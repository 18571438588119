import L, { routing } from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.locatecontrol';
import LeafletGeofence from './LeafletGeofence';
import { store } from '../../../store';
import BaseMap from '../base/BaseMap';
import constants from '../../../config/constants';
import LeafletMarker from './LeafletMarker';
import 'overlapping-marker-spiderfier-leaflet';
import omnivore from '@mapbox/leaflet-omnivore';
import { App } from '../../../App';

 


export default class LeafletMainMap extends BaseMap {
    constructor() {
        super();
        this.markers = {};
        this.geofences = {};
        this.routes = {};

        this._selectedMarkerId = null;
        this._overlappingLayer = null;
        this.onMapClick = null;
    }

    destroy() {
        if (this.map) {
            this.map.off();
            this.map.remove();
        }
    }

    _initMap(target, config = {}) {

        const scaleLine = new L.Control.Scale({
            position: 'bottomright'
        });
        
        const locate = new L.control.locate({
            position: 'bottomright'
        });

       

        this.config = config;

        let mapUrl = config.map_config && config.map_config.url ? config.map_config.url : store.getState().app.mapstate.map_config.url;
        const subdomains = config.map_config && config.map_config.subdomains ? config.map_config.subdomains : store.getState().app.mapstate.map_config.subdomains;
        const lat = config.map_config && config.map_config.latitude ? config.map_config.latitude : store.getState().app.mapstate.map_config.latitude;
        const lon = config.map_config && config.map_config.longitude ? config.map_config.longitude : store.getState().app.mapstate.map_config.longitude;
        const zoom = config.map_config && config.map_config.zoom ? config.map_config.zoom : store.getState().app.mapstate.map_config.zoom;

        const maxZoom = App.App.getAttributePreference('web.maxZoom', config.map_config && config.map_config.maxZoom ? config.map_config.maxZoom : store.getState().app.mapstate.map_config.maxZoom);

        this.tileLayer = new L.TileLayer(mapUrl.replace(/&amp;/g, '&'), {
            attribution: '',
            subdomains: subdomains
        });

        this.map = new L.Map(target, {
            center: [lat, lon],
            zoom: zoom,
            maxZoom: maxZoom,
            attributionControl: false,
            zoomControl: false,
            preferCanvas: true,
            layers: [
                this.tileLayer,
            ]
        });

        this._displayNames = this.config.options.show_names;
        this._displayGeofences = this.config.options.show_geofences;
        this._displayRoutes = this.config.options.show_routes;
        this._clusterEnabled = this.config.options.cluster_enabled;
        this._followDevices = this.config.options.follow_devices;


        scaleLine.addTo(this.map);
        locate.addTo(this.map);


       



        this._loadPoiLayer();
        this._initGeofencesContainerLayer();
        this._initRoutesContainerLayer();
        this._initMarkersContainerLayer(this._clusterEnabled);
        //this.markersContainerLayer.bringToFront();
        //this.geofencesContainerLayer.bringToBack();

        this._addCurrentMarkersToLayer();


        this.markersContainerLayer.on('animationend', () => {
            this._checkRoutesVisibility();
        })

        this._overlappingLayer = new window.OverlappingMarkerSpiderfier(this.map);

        if (this.onMapClick) {
            this.map.on('click', (e) => {
                this.onMapClick(e);
            })
        }

        if (this.onStateChanged) {
            this.onStateChanged({
                latitude: this.map.getCenter().lat,
                longitude: this.map.getCenter().lng,
                zoom: this.map.getZoom(),
            })
            this.map.on('move', () => {
                this.onStateChanged({
                    latitude: this.map.getCenter().lat,
                    longitude: this.map.getCenter().lng,
                    zoom: this.map.getZoom(),
                })
            })
        }

        return this.map;

    }

    _createClusterGroup() {
        this.markersContainerLayer = new L.MarkerClusterGroup({
            renderer: new L.Canvas(),
            chunkedLoading: true,
            animate: false,
            animateAddingMarkers: false,
            disableClusteringAtZoom: this.map.getMaxZoom() - 4,
            spiderfyOnMaxZoom: false,
            showCoverageOnHover: false,
            zoomToBoundsOnClick: true,
            removeOutsideVisibleBounds: true,
            spiderLegPolylineOptions: false
        });
    }

    _initMarkersContainerLayer(clustered = true) {
        if (this.markersContainerLayer) {
            this.markersContainerLayer.clearLayers();
            this.markersContainerLayer.remove();
        }
        if (!clustered) {
            this.markersContainerLayer = new L.LayerGroup({ renderer: new L.Canvas() });
        } else {
            this._createClusterGroup();
        }

        this.markersContainerLayer.addTo(this.map);
    }



    _initRoutesContainerLayer() {
        if (this.routesContainerLayer) {
            this.routesContainerLayer.remove();
        }
        this.routesContainerLayer = new L.LayerGroup();
        if (this._displayRoutes) {
            this.routesContainerLayer.addTo(this.map);
        }
    }


    _initGeofencesContainerLayer() {
        if (this.geofencesContainerLayer) {
            this.geofencesContainerLayer.remove();
        }
        this.geofencesContainerLayer = new L.LayerGroup();
        if (this._displayGeofences) {
            this.geofencesContainerLayer.addTo(this.map);
        }
    }

    /**
     * clear the map
     */
    clear() {
        this.markers = {};
        this.geofences = {};
        this.routes = {};
        if (this._overlappingLayer) {
            this._overlappingLayer.clearMarkers();
        }
        this.markersContainerLayer.clearLayers();
        this.geofencesContainerLayer.clearLayers();
        this.routesContainerLayer.clearLayers();
    }


    updatePositions(data) {
        if (data.constructor !== Array) {
            data = [data];
        }
        data.map(device => {
            const position = this._getLatestPosition(device.id);
            if (device && position) {
                //this.updateAccuracy(position, device);
                this._updateLatestMarker(device, position);
                this._updateRoute(device, position);
                //this.updateLiveRoute(position, device);
            }
            this._checkMarkerVisibility(device.id);
        });
    }

    updateGeofences(data) {
        this.geofencesContainerLayer.clearLayers();
        if (data.constructor !== Array) {
            data = [data];
        }
        data.map(geofence => {
            if (geofence) {
                this._updateGeofence(geofence)
            }
        });
    }



    getStatusColor(device) {
        return constants.device.colors[device.status] || constants.device.colors.unknown;
    }

    getColorOfDevice(device, returnDefault = true) {
        return App.App.getReportColor(device.id, returnDefault);
    }

    _loadPoiLayer() {
        const poiLayer = App.App.getPreference('poiLayer', null);

        if (poiLayer) {
            omnivore.kml(poiLayer).addTo(this.map);
        }

    }

    _addCurrentMarkersToLayer() {

        Object.values(this.markers).map(m => {
            m.parent = this.markersContainerLayer;
            m.addToParent();

        });
        this._checkRoutesVisibility();
    }

    _constructMarkerConfig(device, position) {
        return {
            statusColor: this.getStatusColor(device),
            iconColor: this.getColorOfDevice(device, false),
            lat: position ? position.latitude : 0,
            lng: position ? position.longitude : 0,
            category: device.category,
            rotation: position ? position.course : 0,
            selected: device.selected,
            title: device.name,
            id: device.id,
            hidden: device.hidden,
        }


    }

    _updateLatestMarker(device, position) {
        let marker = this.getMarker(device.id);
        const config = this._constructMarkerConfig(device, position);
        if (!marker) {
            this.addMarker(config);
        } else {
            marker.update(config);
        }

        this._checkMarkerVisibility(device.id);

        if (this._selectedMarkerId === device.id && this._followDevices && (!device.hidden) && marker) {
            this.centerToMarker(marker);
        }
    }

    _getPositionsHistory(id) {
        return store.getState().positions.history[id] || [];
    }

    _getLatestPosition(id) {
        return store.getState().positions.positions[id];
    }

    _updateRoute(device, position) {
        if (!device) {
            return;
        }
        let route = this.getRoute(device.id);


        if (!route) {
            const mapRouteWidth = App.App.getAttributePreference('web.liveRouteLength', constants.mapRouteWidth);
            const points = [];
            this._getPositionsHistory(device.id).map(h => {
                points.push([h.latitude, h.longitude]);
            });
            route = new L.Polyline(points, { name: device.id + '_route' });
            route.bindTooltip(device.name, { direction: 'bottom', sticky: true });
            route.setStyle({
                color: this.getColorOfDevice(device, true),
                weight: mapRouteWidth,
            })
            this.routes[device.id] = route;
            this.routesContainerLayer.addLayer(route);
        } else {
            if (position) {
                route.addLatLng([position.latitude, position.longitude]);
            } else {
                console.error('No position for device ' + device.id)
            }

        }
        return route;
    }

    _checkRoutesVisibility() {
        if (!this._displayRoutes) {
            return;
        }
        Object.values(this.markers).map(d => {
            const route = this.getRoute(d.id);
            if (route) {
                this._updateRouteVisibility(route, this.map.hasLayer(d.marker) && (!d.hidden))
            }
        })
    }

    _updateRouteVisibility(route, visible) {
        if (this.routesContainerLayer.hasLayer(route) && !visible) {
            this.routesContainerLayer.removeLayer(route);
        } else if ((!this.routesContainerLayer.hasLayer(route)) && visible) {
            this.routesContainerLayer.addLayer(route)
        }
    }

    _checkMarkerVisibility(id) {
        const marker = this.getMarker(id);
        if (marker) {
            if (marker.hidden) {
                marker.hide();
            } else {
                marker.show();
            }
            const route = this.getRoute(marker.id);
            if (route) {
                this._updateRouteVisibility(route, this.map.hasLayer(marker.marker) && marker.visible)
            }
        }
    }


    _checkNamesVisibility(visibility) {
        Object.values(this.markers).map(m => {
            if (visibility) {
                m.showName();
            } else {
                m.hideName();
            }
        });
    }


    _updateGeofence(geofence) {
        let feature = this.getGeofence(geofence.id);
        if (!feature) {
            feature = new LeafletGeofence(geofence, this.geofencesContainerLayer);
            feature.init();
            feature.addToParent();
        }
    }

    getMarker(id) {
        return this.markers[id];
    }

    getRoute(id) {
        return this.routes[id];
    }

    getGeofence(id) {
        return this.geofences[id];
    }

    addMarker(config) {
        const marker = new LeafletMarker(this.markersContainerLayer);
        marker.create(config);
        marker.id = config.id;
        marker.addToParent();
        if (this._displayNames) {
            marker.showName();
        } else {
            marker.hideName();
        }
        if (this._overlappingLayer) {
            this._overlappingLayer.addMarker(marker.marker);
        }
        this.markers[config.id] = marker;
        marker.marker.on('click', () => {
            if (this.onDeviceSelected)
                this.onDeviceSelected(marker.id)
        })

        marker.marker.on('mouseover', () => {
            marker.marker.getTooltip().bringToFront();
        });

        marker.marker.on('mouseout', () => {
            marker.marker.getTooltip().bringToBack();
        });


    }

    removeMarker(id) {
        const marker = this.getMarker(id);
        if (marker) {
            marker.remove();
            delete this.markers[id];
            this.removeRoute(id);
        }
    }

    clearMarkers() {
        this.markers = {};
        this.markersContainerLayer.clearLayers();
    }




    followDevices() {
        this._followDevices = true;
    }

    unfollowDevices() {
        this._followDevices = false;
    }


    showGeofences() {
        this._displayGeofences = true;
        if (!this.map.hasLayer(this.geofencesContainerLayer)) {
            this.map.addLayer(this.geofencesContainerLayer);
        }
    }

    hideGeofences() {
        this._displayGeofences = false;
        if (this.map.hasLayer(this.geofencesContainerLayer)) {
            this.map.removeLayer(this.geofencesContainerLayer);
        }
    }

    showObjectNames() {
        if (!this._displayNames) {
            this._displayNames = true;
            this._checkNamesVisibility(this._displayNames);
        }
    }

    hideObjectNames() {
        if (this._displayNames) {
            this._displayNames = false;
            this._checkNamesVisibility(this._displayNames);
        }
    }

    showRoutes() {
        if (!this._displayRoutes) {
            this._displayRoutes = true;
            if (!this.map.hasLayer(this.routesContainerLayer)) {
                this.map.addLayer(this.routesContainerLayer);
            }
            this._checkRoutesVisibility();
        }
    }

    hideRoutes() {
        if (this._displayRoutes) {
            this._displayRoutes = false;
            if (this.map.hasLayer(this.routesContainerLayer)) {
                this.map.removeLayer(this.routesContainerLayer);
            }
        }
    }

    removeRoute(id) {
        const route = this.routes[id];
        if (route) {
            route.remove();
            delete this.routes[id];
        }
    }

    clearRoutes() {
        this.routes = {};
        this.routesContainerLayer.clearLayers();
    }


    enableCluster() {
        if (!this._clusterEnabled) {
            this._clusterEnabled = true;
            this._initMarkersContainerLayer(true);
            this._addCurrentMarkersToLayer();
        }
    }

    disableCluster() {
        if (this._clusterEnabled) {
            this._clusterEnabled = false;
            this._initMarkersContainerLayer(false);
            this._addCurrentMarkersToLayer();
        }

    }

    zoomToAll() {
        const bounds = [];
        Object.values(this.markers).map(m => {
            bounds.push(m.marker.getLatLng());
        });
        this.map.fitBounds(bounds);
    }

    zoomIn() {
        this.map.zoomIn();
    }

    zoomOut() {
        this.map.zoomOut();
    }

    invalidateSize(){
        this.map.invalidateSize();
    }

    centerToPoint(lat, lng) {
        const desiredZoom = App.App.getAttributePreference('web.selectZoom', constants.selectedZoom);
        const zoom = (this.map.getZoom() > desiredZoom) ? this.map.getZoom() : desiredZoom;

        this.map.setView([lat, lng], zoom, {
            pan: {
                animate: false,
                duration: .5
            },
            zoom: {
                animate: true
            }
        });
    }

    centerToMarker(marker) {
        this.centerToPoint(marker.marker.getLatLng().lat, marker.marker.getLatLng().lng);
        this._checkRoutesVisibility(this.routes[marker.id], marker.visible)
    }

    unselectMarker(device) {
        if (!device) {
            return;
        }
        const lastMarker = this.getMarker(device.id);
        if (lastMarker) {
            lastMarker.marker.setZIndexOffset(0);
            lastMarker.marker.getTooltip().bringToBack();
            const position = this._getLatestPosition(device.id);
            lastMarker.update(this._constructMarkerConfig(device, position));
        }
        this._selectedMarkerId = null;

    }

    selectMarker(device) {
        if (this._selectedMarkerId != device.id) {
            this.unselectMarker(store.getState().devices.devices[this._selectedMarkerId]);
            const marker = this.getMarker(device.id);
            if (!marker.visible) {
                return;
            }
            const position = this._getLatestPosition(device.id);
            marker.update(this._constructMarkerConfig(device, position));

            /*if (this._selectedMarkerId) {
                const selectedMarker = this.getMarker(this._selectedMarkerId);
                selectedMarker.update(this._constructMarkerConfig(selectedMarker.device));
            }*/
            this._selectedMarkerId = device.id;

            marker.marker.setZIndexOffset(100000);
            marker.marker.getTooltip().bringToFront();
            this.centerToMarker(marker);
        } else {
            const marker = this.getMarker(device.id);
            this.centerToMarker(marker);
        }
    }
}

