import React from 'react';

export default class CustomCheckbox extends React.Component {
    render() {
        return (
            <label className="custom-chk">
                <input type="checkbox" {...this.props} />
                <span className="checkmark" ></span>
                <strong>{this.props.label}</strong>
            </label>
        )
    }
}