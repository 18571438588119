import { BaseModel } from "./BaseModel";
import { store } from "../store";

export const fields = [{
    name: 'id',
    type: 'int'
}, {
    name: 'name',
    type: 'string'
}, {
    name: 'type',
    type: 'string',
    format: (value) => {
        const attribute = store.getState().maintenance_types.maintenance_types[value]
        return (attribute && attribute.name || value);
    }
}, {
    name: 'start',
    type: 'float'
}, {
    name: 'period',
    type: 'float'
}, {
    name: 'attributes'
}];

export class Maintenance extends BaseModel {
    constructor(data = {}) {
        super(fields, data);
    }
}