import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { t } from '../../utils/Translator';
import { SessionService, AppService } from '../../services';
import { connect } from 'react-redux';
import Page from '../Page';



import { store } from '../../store';
import app from '../../store/app';
import { App } from '../../App';

export class AppHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentModal: null,
            modalProps: {},
            menuOpen: false,
        }

        this.toggleMenu = this.toggleMenu.bind(this);
    }

    async logout() {
        try {
            await SessionService.logout();
        } catch (ex) {
        } finally {
            window.location.reload();
        }
    }

    showModal(config, props = {}) {
        store.dispatch(app.actions.showModal(config))
        //this.setState({ currentModal: config, modalProps: props })
    }

    hideModal() {
        this.setState({ currentModal: null, modalProps: {} })
    }

    renderModal(config, props = {}) {
        const Component = config.component;
        return <Component {...props} onHide={() => this.hideModal()} />
    }

    toggleMenu() {
        this.setState({ menuOpen: !this.state.menuOpen })
    }

    render() {

        const admin = this.props.user.administrator;
        const readonly = AppService.getPreference('readonly', false);

        const configMenu = [
            { name: 'groups', title: t('settingsGroups'), icon: 'mdi mdi-google-circles-extended', enabled: App.App.userHasPermission('groups') },
            { name: 'server', title: t('settingsServer'), icon: 'mdi mdi-server', enabled: App.App.userHasPermission('server') },
            { name: 'drivers', title: t('sharedDrivers'), icon: 'mdi mdi-account-key', enabled: App.App.userHasPermission('drivers') },
            { name: 'geofences', title: t('sharedGeofences'), icon: 'mdi mdi-map-marker-radius', enabled: App.App.userHasPermission('geofences') },
            { name: 'notifications', title: t('sharedNotifications'), icon: 'mdi mdi-bell-ring', enabled: App.App.userHasPermission('notifications') },
            { name: 'calendars', title: t('sharedCalendars'), icon: 'mdi mdi-calendar', enabled: App.App.userHasPermission('calendars') },
            { name: 'savedCommands', title: t('sharedSavedCommands'), icon: 'mdi mdi-apple-keyboard-command', enabled: App.App.userHasPermission('commands') },
            { name: 'computedAttributes', title: t('sharedComputedAttributes'), icon: 'mdi mdi-cast', enabled: App.App.userHasPermission('computed-attributes') },
            { name: 'users', title: t('settingsUsers'), icon: 'mdi mdi-account-multiple', enabled: App.App.userHasPermission('users') },
            { name: 'maintenance', title: t('sharedMaintenance'), icon: 'mdi mdi-wrench', enabled: App.App.userHasPermission('maintenance') },
            { name: 'devices', title: t('deviceTitle'), icon: 'mdi mdi-car', enabled: App.App.userHasPermission('devices') },
            {name: 'statistics', title:t('statisticsTitle'), icon: 'mdi mdi-chart-bar', enabled: App.App.userHasPermission('statics') },
        ];

        const userMenu = [
            { name: 'account', title: t('settingsUser'), icon: 'mdi mdi-account-circle', enabled: App.App.userHasPermission('account') },
            { name: 'accountSettings', title: t('sharedPreferences'), icon: 'mdi mdi-settings', enabled: App.App.userHasPermission('preferences') },
        ]


        //const { intl } = this.props;
        return (
            <React.Fragment>
                <Navbar expand="lg" variant="dark" bg="dark border-bottom" className="app-header" fixed="top">
                    <Navbar.Brand>
                        <img src="img/logo-light.svg" alt="IQTracking" height="30px" />
                    </Navbar.Brand>

                    <div className="ml-auto right-content">
                       
                        <button className={"hamburger hamburger--slider " + (this.state.menuOpen ? 'is-active' : '')} onClick={this.toggleMenu} type="button">
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>


                        {/*<NavDropdown title={<i className="mdi mdi-settings"></i>}>
                            <div>
                                {
                                    Object.values(configMenu).sort((a, b) => a.title > b.title ? 1 : -1).map(o => {
                                        return (
                                            o.enabled ? <NavDropdown.Item key={o.name} onClick={() => this.showModal(o)}><i className={o.icon}></i> {o.title}</NavDropdown.Item> : null
                                        );
                                    })
                                }
                            </div>
                        </NavDropdown>
                        <NavDropdown title={<i className="mdi mdi-account-circle"></i>}>
                            <div>
                                {
                                    Object.values(userMenu).map(o => {
                                        return (
                                            <NavDropdown.Item key={o.name} onClick={() => this.showModal(o)}><i className={o.icon}></i> {o.title}</NavDropdown.Item>
                                        );
                                    })
                                }
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={this.logout}><i className="mdi mdi-logout-variant"></i> {t('loginLogout')}</NavDropdown.Item>
                            </div>
                            </NavDropdown>*/}

                    </div>
                </Navbar>
                <Nav className={"main-menu " + (this.state.menuOpen ? 'active' : '')}>
                    <div className="overlay" onClick={this.toggleMenu}></div>
                    <div className="content" onClick={this.toggleMenu}>
                        <div className="header">
                            <img src="img/logo-dark.svg" alt="IQTracking" height="30px" />
                        </div>
                        <div className="menu">
                            {
                                Object.values(userMenu).map(o => {
                                    return (
                                        o.enabled ? <NavDropdown.Item key={o.name} onClick={() => this.showModal(o)}><i className={o.icon}></i> {o.title}</NavDropdown.Item> : null
                                    );
                                })
                            }
                            <hr />
                            {
                                Object.values(configMenu).sort((a, b) => a.title > b.title ? 1 : -1).map(o => {
                                    return (
                                        o.enabled ? <NavDropdown.Item key={o.name} onClick={() => this.showModal(o)}><i className={o.icon}></i> {o.title}</NavDropdown.Item> : null
                                    );
                                })
                            }
                            <hr />
                            <NavDropdown.Item onClick={this.logout}><i className="mdi mdi-logout-variant"></i> {t('loginLogout')}</NavDropdown.Item>
                        </div>
                    </div>
                </Nav>


                {/*this.state.currentModal ? this.renderModal(this.state.currentModal, this.state.modalProps) : null*/}
                {this.state.currentModal ? <Page open={true} onHide={() => this.setState({ currentModal: null })} title={<h1 className="title"><i className={this.state.currentModal.icon}></i> {this.state.currentModal.title}</h1>}>
                    {this.renderModal(this.state.currentModal, this.state.modalProps)}
                </Page> : null}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user
})


export default connect(mapStateToProps, null)(AppHeader);