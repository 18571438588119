import React from 'react';
import { connect } from 'react-redux';
import events_store from '../../store/events';
import app from '../../store/app';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { t } from '../../utils/Translator';
import events from '../../utils/Events';
import devices from '../../store/devices';

export class DeviceListSettings extends React.PureComponent {

    constructor(props) {
        super(props);
        this.toggleDisplay = this.toggleDisplay.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
    }

    toggleDisplay(e, option) {
        e.stopPropagation();
        option.show = !option.show;
        this.show = true;
        this.forceUpdate();
        events.emit(devices.types.CHANGE_DISPLAY_ATTRIBUTES)
    }

    handleToggle(isOpen, event, metadata) {
        if (metadata.source !== 'select') {
            this.show = !this.show;
            this.forceUpdate();
        }
        //event.persist();
    }

    render() {
        const options = this.props.settings;
        return (
            <DropdownButton
                variant="light"
                size="sm"
                className="shadow-none"
                title={<i className="mdi mdi-settings"></i>}
                show={this.show}
                onToggle={this.handleToggle}
            >
                {options.map(option => {
                    return <Dropdown.Item key={option.key} eventKey={option.key} onClick={(e) => this.toggleDisplay(e, option)}>
                        <label className="custom-chk">
                            <input type="checkbox" checked={option.show} readOnly />
                            <span className="checkmark" onClick={(e) => null}></span>
                            {t(option.title)}
                        </label>

                    </Dropdown.Item>

                })}

            </DropdownButton>
        )
    }
}


const mapStateToProps = (state) => ({
    settings: Object.values(state.devices.device_attributes).filter(o => o.canUnselect === true)
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(DeviceListSettings)