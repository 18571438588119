import { t } from "../../utils/Translator";
import React from 'react';
import { Modal, Form, Button, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { CommandsService, AppService, CommandTypesService } from "../../services";
import { Command } from "../../models";
import CustomSelect from '../Controls/CustomSelect';
import CommandForm from '../Crud/SavedCommands/form';
import { App } from "../../App";
import { Formik } from 'formik';
import * as yup from 'yup';
import CustomCheckbox from "../Controls/CustomCheckBox";
import { store } from "../../store";
import CustomNumberField from "../Controls/CustomNumberField";
import { deepClone } from "../../utils/functions";

export default class CommandModal extends React.Component {
    constructor(props) {
        super(props);
        this.commands = [];
        this.state = {
            commandOptions: [],
            commandTypes: [],
            command: null,
        }

        this.onCommandChange = this.onCommandChange.bind(this);
        this.changeType = this.changeType.bind(this);
        this.send = this.send.bind(this)
    }

    async send(values) {
        try {
            if (this.state.command) {
                this.state.command.deviceId = this.props.device.id;
                const response = await CommandsService.send(this.state.command)
                if (response.status === 202) {
                    App.App.showToast(t('commandQueued'), '', {
                        type: 'success',
                    })
                } else {
                    App.App.showToast(t('commandSent'), '', {
                        type: 'success',
                    })
                }
                this.props.onHide();
            }
        } catch (ex) {
            App.App.showError(ex);
        }
    }

    componentWillMount() {
        CommandsService.getSendOptions(this.props.device.id).then(data => {

            if (!App.App.getPreference('limitCommands', false)) {
                data.unshift(new Command().deserialize({
                    description: t('sharedNew'),
                    id: 0
                }))
            }

            this.commands = data;
            const commandOptions = [];
            data.map(i => {
                commandOptions.push({ value: i.id, label: i.description });
            })
            this.setState({ commandOptions: commandOptions })

        }).catch(ex => {
            AppService.showError(ex)
        })

        CommandTypesService.list({ deviceId: this.props.device.id }, false).then(data => {
            const commandTypes = [];
            data.map(i => {
                commandTypes.push({ value: i.pk, label: i.name });
            })
            commandTypes.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
            this.setState({ commandTypes: commandTypes })
        }).catch(ex => {
            AppService.showError(ex)
        })
    }

    onCommandChange(name, value) {
        this.setState({ command: this.commands.find(o => o.id === value) })
    }

    constructConfig(type) {
        const knownCommand = store.getState().known_commands.known_commands[type];
        const { command } = this.state;
        const fields = [];
        if (knownCommand && knownCommand.parameters) {
            const parameters = knownCommand.parameters;

            parameters.map(p => {
                const config = {};
                config.key = p.key;
                config.label = t(p.name);
                if (command.attributes && command.attributes[config.key]) {
                    config.value = command.attributes[config.key];
                }
                config.dataType = p.dataType;

                switch (p.valueType) {
                    case 'number':
                        config.type = 'number';
                        config.minValue = p.minValue;
                        config.maxValue = p.maxValue;
                        config.step = p.allowDecimals !== undefined && (!p.allowDecimals) ? 1 : 0.01;
                        break;
                    case 'boolean':
                        config.type = 'boolean';
                        break;
                    default:
                        if (p.dataType === 'timezone') {
                            config.type = 'select';
                            config.options = [];
                            Object.values(store.getState().timezones.timezones).map(o => {
                                config.options.push({ name: o.key, value: o.key })
                            })
                            config.options.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
                        } else {
                            config.type = 'text';
                        }
                }
                fields.push(config);
            })
        }

        this.setState({
            fields: fields
        })
    }

    changeType(value, setFieldValue) {
        setFieldValue('type', value);
        this.constructConfig(value);
        const command = new Command().deserialize(deepClone(this.state.command));
        command.type = value;
        this.setState({
            command: command
        })
    }

    setParameter(e, setFieldValue) {
        const name = e.target.name;
        const value = e.target.value;
        setFieldValue(name, value);
        const command = new Command().deserialize(deepClone(this.state.command));
        command.setAttribute(name.replace('parameters.', ''), value)
        this.setState({
            command: command
        })
    }

    render() {
        const { show, onHide } = this.props;
        const { command } = this.state;
        const schema = {};

        return <Modal show={true} size="md" className="custom-modal attributes-modal form" centered onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t('commandTitle')}</Modal.Title>
            </Modal.Header>
            <Formik
                validationSchema={schema}
                onSubmit={this.send}
                initialValues={{
                    commandId: command ? command.id : null,
                    type: command ? command.type : null,
                    textChannel: command ? command.textChannel : null,
                    parameters: command && command.attributes && command.attributes.parameters ? command.attributes.parameters : {},
                }}
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    dirty,
                    isSubmitting,
                    errors,
                    isValid,
                    setFieldValue }) => {
                    return (
                        <Form onSubmit={handleSubmit} noValidate className="d-flex flex-column h-100 flex-grow-1 form form-sm ">
                            <Modal.Body className="visible">



                                <FormGroup>
                                    <FormLabel>{t('commandTitle')}</FormLabel>
                                    <CustomSelect name="commandId" defaultValue={values.commandId} options={this.state.commandOptions} onChange={(e, v) => {
                                        this.onCommandChange(e, v);
                                        setFieldValue(e, v)
                                    }} />
                                </FormGroup>

                                {values.commandId === 0 ?
                                    <React.Fragment>
                                        <FormGroup className="mt-3">
                                            <CustomCheckbox name="textChannel" value="textChannel" defaultChecked={values.textChannel} onChange={(e) => {
                                                const command = new Command().deserialize(deepClone(this.state.command));
                                                command.textChannel = e.target.checked;
                                                this.setState({
                                                    command: command
                                                })
                                            }} label={t('commandSendSms')} />
                                        </FormGroup>

                                        <FormGroup>
                                            <FormLabel>{t("sharedType")}</FormLabel>
                                            <CustomSelect name="type" defaultValue={values.type} options={this.state.commandTypes} onChange={(name, value) => this.changeType(value, setFieldValue)} />
                                        </FormGroup>

                                        {this.state.fields ? this.state.fields.map(o => {
                                            return <FormGroup key={o.key}>
                                                {o.type !== 'boolean' ?
                                                    <FormLabel>{t(o.label)}</FormLabel> : null}

                                                {(() => {
                                                    switch (o.type) {
                                                        case 'number':
                                                            return <CustomNumberField
                                                                type="number"
                                                                name={'parameters.' + o.key}
                                                                value={o.value}
                                                                xdatatype={o.dataType}
                                                                onChange={(e) => {
                                                                    this.setParameter(e, setFieldValue);
                                                                }}
                                                                min={o.minValue}
                                                                max={o.maxValue}
                                                                step={o.step}
                                                            />
                                                        case 'boolean':
                                                            return <CustomCheckbox name={'parameters.' + o.key} value={o.key} defaultChecked={o.value} onChange={(e) => {
                                                                this.setParameter(e, setFieldValue);
                                                            }} label={t(o.label)} />
                                                        case 'select':
                                                            return <FormControl
                                                                as="select"
                                                                name={'parameters.' + o.key}
                                                                value={o.value}
                                                                onChange={(e) => {
                                                                    this.setParameter(e, setFieldValue);
                                                                }}
                                                            >
                                                                <option value="">----</option>
                                                                {o.options.map(o => {
                                                                    return (<option key={o.value} value={o.value}>{o.name}</option>)
                                                                })}
                                                            </FormControl>
                                                        default:
                                                            return <FormControl
                                                                type="text"
                                                                name={'parameters.' + o.key}
                                                                defaultValue={o.value}
                                                                onChange={(e) => {
                                                                    this.setParameter(e, setFieldValue);
                                                                }}
                                                            />
                                                    }
                                                })()}
                                            </FormGroup>
                                        }) : null}
                                    </React.Fragment>
                                    : null}

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={onHide}>
                                    <i className="mdi mdi-close"></i> {t('sharedCancel')}
                                </Button>
                                <Button variant="primary" type="submit">
                                    <i className="mdi mdi-check"></i> {t('commandSend')}
                                </Button>
                            </Modal.Footer>
                        </Form>)

                }}
            </Formik>
        </Modal>
    }
}