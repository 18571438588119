import ApiService from "./ApiService";
import { store } from "../store";
import devices from "../store/devices";
import positions from "../store/positions";
import { Position } from "../models";
import events from "../utils/Events";
import { convertToQs } from '../utils/functions';

export class PositionsService extends ApiService {
    constructor() {
        super();
        this.baseUrl = 'positions';
    }

    async load(params = {}) {
        const items = await super.list(params);
        //update the store
        store.dispatch({ type: positions.types.LOADED, payload: { items: items } });
        events.emit(positions.types.LOADED, { positions: items });

        /*items.map(p => {
            const device = store.getState().devices.devices[p.deviceId];
            if (device && ((!device.position) || device.position.id != p.id)) {
                device.history = [];
                device.position = p;
                store.dispatch(devices.actions.updated(device));
                events.emit(devices.types.UPDATED, device);
            }
        });*/

        return items;
    }

    async findAddressFromPosition(position) {
        const address =  await this.findAddressFromLatLng(position.latitude, position.longitude);
        position.address = address;
        store.dispatch(positions.actions.updated(position));
    }

    async findAddressFromLatLng(latitude, longitude) {
        const { data } = await this.api.get('/server/geocode/?' + convertToQs({ latitude, longitude }));
        return data;
    }


    transformSingleResponse(data) {
        const d = new Position().deserialize(data);
        return d;
    }


}

export default new PositionsService();