import React from 'react';
import events from '../../utils/Events';
import { AppService } from '../../services';

export default class AppSidebar extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { toggled: false, open: AppService.isMobile ? false : true };
        this.toggle = this.toggle.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }

    componentWillReceiveProps(newProps, newState) {
        if (newProps.open !== undefined) {
            this.setState({ open: newProps.open })
        }
    }


    toggle() {
        this.setState({ toggled: !this.state.toggled, open: !this.state.opened })

    }

    open() {
        this.setState({ toggled: AppService.isMobile })
    }

    close() {
        this.setState({ toggled: !AppService.isMobile })
    }

    componentWillMount() {
        events.on('CLOSE_SIDEBAR', this.close);
        events.on('OPEN_SIDEBAR', this.open);
    }

    componentWillUnmount() {
        events.off('CLOSE_SIDEBAR', this.close)
        events.off('OPEN_SIDEBAR', this.open);
    }

    renderControl() {
        return (
            <div className="control" onClick={this.toggle}>
                <i className="mdi button"></i>
            </div>
        )
    }

    render() {
        return (
            <div className={'app-sidebar ' + (this.state.toggled ? 'toggled' : '') + ' ' + (this.props.className || '')}>
                <div className="content">
                    {this.state.open || true ? this.props.children : null}
                    {(this.props.controlled != undefined || !this.props.controlled ? this.renderControl() : null)}
                </div>
                <div className="overlay" onClick={this.toggle}></div>
            </div>
        )
    }
}