import React from 'react';
import { ToggleButton, ToggleButtonGroup, Button, DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap';
import { t } from '../../utils/Translator';
import events from '../../utils/Events';
import constants from '../../config/constants';
import { store } from '../../store';
import { AppService } from '../../services';
import app from '../../store/app';

 
export class MapControls extends React.PureComponent {

    fitMapBounds() {
        events.emit('MainMap/ZOOM_TO_ALL');
    }

    zoomIn(){
        events.emit('MainMap/ZOOM_IN');
    }

    zoomOut(){
        events.emit('MainMap/ZOOM_OUT');
    }

    toggleFullScreen() {
        const is_full_screen = store.getState().app.mapstate.is_full_screen;
        store.dispatch({ type: app.types.MAP_FULL_SCREEN, payload: !is_full_screen });
        events.emit(app.types.MAP_FULL_SCREEN, !is_full_screen)
    }


    async changeMapConfig(config) {
        if (config.value === store.getState().app.mapstate.map_config.type) {
            return;
        }

        const new_config = config.config;
        new_config.type = config.value;

        const current_config = store.getState().app.mapstate.map_config;
        current_config.type = config.value;
        AppService.changeMapPreferences(current_config);

        AppService.user.map = config.value;
        await AppService.saveUserPreferences();

        this.forceUpdate();
    }

    changeOptions(values) {

        const config = store.getState().app.mapstate.options;
        const new_config = {
            ...config,
            show_geofences: values.includes("show_geofences"),
            cluster_enabled: values.includes("cluster_enabled"),
            show_routes: values.includes("show_routes"),
            show_names: values.includes("show_names"),
            follow_devices: values.includes("follow_devices")
        };

        //console.log(new_config);
        store.dispatch({ type: app.types.MAP_OPTIONS_CHANGE, payload: new_config });
        events.emit(app.types.MAP_OPTIONS_CHANGE, new_config);
        this.forceUpdate();
    }

    disconnect() {
        AppService.toggleConnection();
    }

    render() {

        const mapState = store.getState().app.mapstate;

        const {
            is_full_screen,
            show_routes,
            show_devices,
            show_names,
            fit_objects,
            cluster_enabled,
            follow_devices,
            show_geofences
        } = mapState.options;

        const { map_config } = mapState;


        const mapOptions = [];
        Object.keys(constants.mapTypes[constants.selectedMapType].mapServices).map(i => {
            const c = constants.mapTypes[constants.selectedMapType].mapServices[i];
            if (c.enabled) {
                mapOptions.push({ name: t(c.name), value: i, selected: map_config.type == i, config: c });
            }

        });

        const control_values = [];
        if (show_routes) {
            control_values.push("show_routes");
        }

        if (show_devices) {
            control_values.push("show_devices");
        }

        if (show_names) {
            control_values.push("show_names");
        }

        if (fit_objects) {
            control_values.push("fit_objects");
        }

        if (show_geofences) {
            control_values.push("show_geofences");
        }

        if (cluster_enabled) {
            control_values.push("cluster_enabled");
        }

        if (follow_devices) {
            control_values.push("follow_devices");
        }


        return (
            <div className="map-controls">
                <div className="section">
                    <ToggleButtonGroup
                        type="checkbox"
                        className="btn-group-vertical section"
                    >
                        <ToggleButton value={1} checked={is_full_screen} onChange={() => this.toggleFullScreen()} size="sm" variant="light" title={t('fullScreen')}><i className="mdi mdi-arrow-expand-all"></i></ToggleButton>
                    </ToggleButtonGroup>
                    <ButtonGroup className="btn-group-vertical">
                        <Button size="sm" variant="light" onClick={this.zoomOut}><i className="mdi mdi-minus"></i></Button>
                        <Button size="sm" variant="light" onClick={this.zoomIn}><i className="mdi mdi-plus"></i></Button>
                    </ButtonGroup>
                </div>
                <div className="section">
                    <DropdownButton
                        drop='left'
                        variant="light"
                        title={<i className="mdi mdi-map-outline"></i>}

                    >
                        {mapOptions.map(o => {
                            return (
                                <Dropdown.Item key={o.value} eventKey={o.value} onClick={(e) => this.changeMapConfig(o)}>
                                    <label className="custom-chk">
                                        <input type="checkbox" checked={o.selected} onChange={(e) => null} />
                                        <span className="checkmark" onClick={(e) => null}></span>
                                        {o.name}
                                    </label>

                                </Dropdown.Item>
                            )
                        })}

                    </DropdownButton>
                </div>


                <ToggleButtonGroup
                    type="checkbox"
                    className="btn-group-vertical"
                    defaultValue={control_values}
                    value={control_values}
                    onChange={(values) => this.changeOptions(values)}
                >
                    <ToggleButton value="cluster_enabled" size="sm" variant="light" title={t('Cluster')}><i className="mdi mdi-google-circles-group"></i></ToggleButton>
                    <ToggleButton value="show_geofences" size="sm" variant="light" title={t('sharedGeofences')}><i className="mdi mdi-map-marker-radius"></i></ToggleButton>
                    <ToggleButton value="show_routes" size="sm" variant="light" title={t('mapLiveRoutes')}><i className="mdi mdi-chart-line-variant"></i></ToggleButton>
                    <ToggleButton value="follow_devices" size="sm" variant="light" title={t('deviceFollow')}><i className="mdi mdi-target"></i></ToggleButton>
                    <ToggleButton value="show_names" size="sm" variant="light" title={t('reportDeviceName')}><i className="mdi mdi-tag-outline"></i></ToggleButton>
                    <Button size="sm" variant="light" title={t('notificationAlways')} onClick={(e) => this.fitMapBounds()}><i className="mdi mdi-eye"></i></Button>
                    <ToggleButton value="CurrentLocationMap" size="sm" variant="light" title={t('Location')}><i className="mdi mdi-flag-outline"></i></ToggleButton>

                    {(process.env.NODE_ENV !== 'production') ?
                        <Button size="sm" variant="light" title="disconnect" onClick={(e) => this.disconnect()}><i className="mdi mdi-lan-pending"></i></Button> : null
                    }



                </ToggleButtonGroup>
            </div>
        );
    }
}

export default MapControls;