import api from '../utils/Api';
import { convertToQs } from '../utils/functions';
import { Position, ReportStop, ReportTrip, ReportSummary } from '../models';

export class ReportsService {
    constructor() {
        this.baseUrl = 'reports';
    }

    async getReport(type, filter, config = { showMarkers: true }) {
        const { data } = await api.get(this.baseUrl + '/' + type + '?' + convertToQs(filter));
        const ret = {
            reportType: 'route',
            filter: filter,
            config: config,
            data: {}
        };
        // eslint-disable-next-line array-callback-return
        data.map(d => {

            let p;
            switch (type) {
                case 'trips':
                    p = new ReportTrip().deserialize(d);
                    break;
                case 'stops':
                    p = new ReportStop().deserialize(d);
                    break;
                case 'summary':
                    p = new ReportSummary().deserialize(d);
                    break;
                default:
                    p = new Position().deserialize(d);

            }
            if (!ret.data[p.deviceId]) {
                ret.data[p.deviceId] = [];
            }
            ret.data[p.deviceId].push(p);

        });
        return ret;
    }

    async reportExel(type, filter, email = false) {
        const { data } = await api.get(this.baseUrl + '/' + type + '?' + convertToQs(filter), {
            headers: {
                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'blob', // important
        });
        if (!email) {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', type + '.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }


}

export default new ReportsService();