import React from 'react';
import AttributesPage from '../Attributes';
import { Button, Form, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { t } from '../../../utils/Translator';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Group } from '../../../models';
import { deepClone, isEqual } from '../../../utils/functions';
import CustomSelect from '../../Controls/CustomSelect';
import { store } from '../../../store';
import PermissionsToolbar from '../Permissions/toolbar';
import { GroupsService, AppService } from '../../../services';


export default class GroupForm extends React.Component {
    constructor(props) {
        super(props);



        const groupOptions = [];
        Object.values(store.getState().groups.groups).map(v => {
            if (v.id > 0 && this.props.item.id != v.id) {
                groupOptions.push({ value: v.id, label: v.name });
            }

        })



        groupOptions.sort((a, b) => a.label > b.label ? 1 : -1);

        this.state = {
            attributesPage: false,
            item: new Group().deserialize(deepClone(props.item)),
            mode: props.mode,
            sending: false,
            changed: false,
            groupOptions: groupOptions,
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.openAttributesPage = this.openAttributesPage.bind(this);
        this.onAttributesCancel = this.onAttributesCancel.bind(this);
        this.onAttributesClosed = this.onAttributesClosed.bind(this);
    }

    async save(values) {
        const item = this.state.item.deserialize(values);
        if (this.props.onSave) {
            this.props.onSave(item);
        } else {
            try {
                if (this.state.item.id > 0) {
                    await GroupsService.update(this.state.item);
                } else {
                    await GroupsService.save(this.state.item);
                }
            } catch (ex) {
                AppService.showError(ex);
            }

        }

    }

    cancel() {
        this.props.onHide();
    }

    openAttributesPage() {
        this.setState({ attributesPage: true })
    }

    onAttributesCancel() {
        const clone = new Group().deserialize(deepClone(this.props.item));
        const item = this.state.item;
        item.attributes = clone.attributes;
        this.setState({
            ...this.state,
            item,
            attributesPage: false
        })
    }

    onAttributesClosed() {
        this.setState({
            ...this.state,
            attributesPage: false,
            changed: !isEqual(this.state.item.attributes, this.props.item.attributes)
        })
    }


    componentWillReceiveProps(newProps, newState) {
        this.setState({ item: new Group().deserialize(deepClone(newProps.item)) })
    }



    getMapState(setFieldValue) {
        const state = store.getState().app.mapstate;
        setFieldValue('zoom', state.zoom)
        setFieldValue('latitude', state.latitude)
        setFieldValue('longitude', state.longitude)
    }



    render() {
        const { sending, groupOptions, item } = this.state;



        const schema = yup.object({
            name: yup.string().required(),
        });

        return <React.Fragment>
            {this.state.attributesPage ?
                <AttributesPage item={item} type="groupsAttributes" onHide={() => this.onAttributesClosed()} onSave={() => this.onAttributesClosed()} onCancel={() => this.onAttributesCancel()} />
                : null}

            <div className="container-wrapper">
                <Formik
                    validationSchema={schema}
                    onSubmit={this.save}
                    disabled={sending}
                    initialValues={{
                        name: item.name,
                        groupId: item.groupId
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        handleChange,
                        dirty,
                        isSubmitting,
                        errors,
                        isValid,
                        setFieldValue
                    }) => (

                            <Form onSubmit={handleSubmit} noValidate className="d-flex flex-column h-100 flex-grow-1 form form-sm ">
                                <React.Fragment>
                                    <div className="flex-grow-1 pt-3 overflow">
                                        <div className="container">
                                            <h3 className="internal-title"><i className="mdi mdi mdi-google-circles-extended"></i> {this.state.item.id ? t('sharedEdit') : t('sharedAdd')} {t('groupParent')}</h3>
                                            {this.state.item.id ? <PermissionsToolbar item={this.state.item} /> : null}

                                            <FormGroup>
                                                <FormLabel>{t("sharedName")}</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="name"
                                                    defaultValue={values.name}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.name}
                                                    required />
                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel>{t("groupParent")}</FormLabel>
                                                <CustomSelect options={groupOptions} name="groupId" isClearable defaultValue={values.groupId} onChange={setFieldValue} />
                                            </FormGroup>

                                        </div>
                                    </div>
                                    <footer className="footer">
                                        <div className="container d-flex">
                                            <div className="flex-grow-1 d-flex">
                                                <Button variant="secondary" className="align-self-stretch" onClick={() => this.openAttributesPage()}>
                                                    <i className="mdi mdi-playlist-play"></i> {t('sharedAttributes')}
                                                </Button>
                                            </div>
                                            <Button variant="secondary" onClick={() => this.props.onHide()}>
                                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                                            </Button>
                                            <Button type="submit" variant="primary" disabled={!this.state.changed && (!dirty || isSubmitting || !isValid)}>
                                                <i className="mdi mdi-content-save"></i> {t('sharedSave')}
                                            </Button>
                                        </div>
                                    </footer>
                                </React.Fragment>
                            </Form>
                        )}
                </Formik>
            </div>
        </React.Fragment>
    }
}