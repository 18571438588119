import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/main.scss';
import './config/device_images';
import Main from './Main';
import * as serviceWorker from './serviceWorker';
import { AppService } from './services';



// Hack for new versions of Android
if (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('OPR') !== -1) {
    var __originalUserAgent = navigator.userAgent;
    navigator.__defineGetter__('userAgent', function () { return __originalUserAgent.replace(/\/OPR[^)]*/g, ''); });
}


window.updateNotificationToken = function (token) {
    AppService.updateNotificationToken(token);
};

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
