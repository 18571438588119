//const imagesId = ['arrow','animal', 'bicycle', 'boat', 'bus', 'car', 'crane', 'helicopter', 'default', 'motorcycle', 'offroad', 'person', 'pickup', 'plane', 'ship', 'tractor', 'train', 'tram', 'trolleybus', 'truck', 'van'];
const imagesId = ['arrow', 'bus', 'car', 'default', 'pickup', 'truck', 'van', 'person', 'bicycle'];
const images = {};

const addSvgFile = (file, id) => {
    const svg = document.createElement('object');
    svg.setAttribute('id', id);
    svg.setAttribute('data', file);
    svg.setAttribute('type', 'image/svg+xml');
    svg.setAttribute('style', 'visibility:hidden;position:absolute;left:-100px;');
    document.body.appendChild(svg);
}

imagesId.map(i => {
    addSvgFile('img/markers/' + i + '.svg', i + 'Svg');
    /*images[i] = {
        key: i + 'Svg',
        name: i,
        svg: document.getElementById(i + 'Svg').contentDocument,
        statusFillId: i === 'arrow' ? 'arrow' : i === 'car' || i === 'default' || i === 'van' || i === 'pickup' || i === 'truck' || i === 'bus' ? 'status' : 'background',
        iconFillId: i === 'car' || i === 'default' || i === 'pickup'? 'icon' : null,
        rotateId: i === 'arrow' ? 'arrow' : i === 'car' || i === 'default' || i === 'van' || i === 'pickup' || i === 'truck' || i === 'bus' ? 'marker' : 'background',
        scaleId: i === 'arrow' ? 'arrow' : i === 'car' || i === 'default' || i === 'van' || i === 'pickup' || i === 'truck' || i === 'bus' ? 'marker' : 'layer1'
    }*/
    images[i] = {
        key: i + 'Svg',
        name: i,
        svg: document.getElementById(i + 'Svg').contentDocument,
        statusFillId: i === 'arrow' ? 'arrow' : 'status',
        iconFillId: i === 'arrow' ? null : 'icon',
        rotateId: i === 'arrow' ? 'arrow' : 'marker',
        scaleId: i === 'arrow' ? 'arrow' : 'marker'
    }
    return images[i];
});


export default {
    ids: imagesId,
    images: images
};