import React from 'react';
import { ReportMap } from '../../components/ReportMap';
import { store } from '../../store';
import Page from '../../components/Page';
import { t } from '../../utils/Translator';
import Events from '../../utils/Events';
import ReportData from './report-data';
import LoadingOverlay from '../../components/LoadingOverlay';

export default class ReportPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false
        }

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            toggled: !this.state.toggled
        }, () => {
            this.forceUpdate();
            setTimeout(()=>{
                Events.emit('reports/TOGGLE', this.state.toggled);
            }, 500);
        })
    }


    shouldComponentUpdate() {
        return false;
    }

    onHide() {
        document.body.classList.remove('show-report');
        Events.emit('reports/HIDE');
    }


    render() {
        return (
            <div className="report-page">

                <Page onHide={this.onHide} title={<React.Fragment><h3 className="title"><i className="mdi mdi-report"></i> {t('reportTitle')}</h3></React.Fragment>}>
                    <div className="map">
                        <ReportMap mapConfig={store.getState().app.mapstate}></ReportMap>
                    </div>
                    <div className={"data " + (this.state.toggled ? 'toggled' : '')}>
                        <div className="control" onClick={this.toggle}>
                            <i className="mdi button"></i>
                        </div>
                        <ReportData />
                    </div>
                </Page>
                <LoadingOverlay />
            </div>
        )
    }
}