import { store } from "../store";
import devices from "../store/devices";
import { Device } from "../models";
import events from "../utils/Events";
import ApiStoreService from "./ApiStoreService";

export class DevicesService extends ApiStoreService {
    constructor() {
        super();
        this.baseUrl = 'devices';
        this.storeName = devices;
    }

    async load(params = {}) {
        const items = await super.list(params);
        //update the store
        events.emit(devices.types.LOADED, { devices: items });
        return items;
    }

    async saveAcumulators(values = {}) {
        const { data } = await this.api.put(this.baseUrl + '/' + values.deviceId + '/accumulators', values);
        return data;
    }

    transformSingleResponse(data) {
        const d = new Device().deserialize(data);
        return d;
    }


}

export default new DevicesService();