import { t } from "../Translator";
import { App } from "../../App";

class SpeedUnitsConverter {
    convertValue(value, unit, back) {
        if (!unit) {
            unit = 'kn';
        }
        const model = App.Store.getState().speed_units.speed_units[unit];
        return back ? value / model.factor : value * model.factor;
    }

    formatValue(value, unit, convert) {
        if (!unit) {
            unit = 'kn';
        }
        const model = App.Store.getState().speed_units.speed_units[unit];
        return (convert ? this.convertValue(value, unit) : value).toFixed(0) + ' ' + t(model.name);
    }
}

export default new SpeedUnitsConverter();