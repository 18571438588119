import React from 'react';
import { connect } from 'react-redux';
import { ListGroup, Button } from 'react-bootstrap';
import { t } from '../../../utils/Translator';
import { AppService, GeofencesService } from '../../../services';
import Page from '../../Page';
import MaintenanceForm from './form';
import { Maintenance, Geofence } from '../../../models';
import Events from '../../../utils/Events';
import geofences from '../../../store/geofences';
import EmptyList from '../../EmptyList';
import TextFilter from '../../Controls/TextFilter';
import List from './list';

class GeofenceListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: props.showForm || false,
            item: props.item || false
        }

        this.hideForm = this.hideForm.bind(this);
        this.showForm = this.showForm.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    async deleteItem(id) {
        if (await AppService.showDeleteConfirm()) {
            await GeofencesService.destroy(id);
            Events.emit(geofences.types.DELETED, id);
        }
    }

    showForm(id) {
        this.setState({
            showForm: true,
            item: id ? new Geofence().deserialize(this.props.items[id]) : new Maintenance()
        })
    }

    async onSave(item) {
        try {
            if (item.id) {
                await GeofencesService.update(item)
            } else {
                await GeofencesService.save(item);
            }
            this.hideForm();
            Events.emit(geofences.types.UPDATED, item);
        } catch (ex) {
            AppService.showError(ex);
        }
    }

    hideForm() {
        this.setState({
            showForm: false,
            item: null
        })
    }

    setFilter(value) {
        if (value != this.state.filter) {
            this.setState({ filter: value })
        }
    }

    filterValues(values) {
        if (this.state.filter && this.state.filter != '') {
            return Object.values(values).filter(o => o.name.toLowerCase().includes(this.state.filter.toLowerCase()))
        }
        return Object.values(values)
    }

    render() {
        const { onHide } = this.props;
        return <React.Fragment>
            {this.state.showForm ?
                <Page onHide={this.hideForm} title={<React.Fragment><h3 className="title"><i className="mdi mdi-map-marker-radius"></i> {this.state.item.id ? t('sharedEdit') : t('sharedAdd')} {t('sharedGeofence')}</h3></React.Fragment>}>
                    <MaintenanceForm item={this.state.item} onSave={this.onSave} onHide={this.hideForm}></MaintenanceForm>
                </Page>
                : null}

            <div className="container-wrapper grow">
                <div className="d-flex flex-column h-100 flex-grow-1 form form-sm ">
                    <div className="flex-grow-1 pt-3 overflow">
                        <div className="container">
                            <List items={this.props.items} onDelete={this.deleteItem} onSelect={this.showForm} enableDelete={true} enableSelection={false} />
                        </div>
                    </div>
                    <footer className="footer">
                        <div className="container d-flex">
                            <div className="flex-grow-1 d-flex">
                                <Button variant="secondary" className="align-self-stretch" onClick={() => this.showForm('')}>
                                    <i className="mdi mdi-plus-circle-outline"></i> {t('sharedAdd')}
                                </Button>
                            </div>
                            <Button variant="secondary" onClick={onHide}>
                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                            </Button>
                        </div>
                    </footer>
                </div>
            </div>
        </React.Fragment>
    }
}


const mapStateToProps = (state) => ({
    items: state.geofences.geofences
})

export default connect(mapStateToProps, null)(GeofenceListPage);