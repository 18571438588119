import moment from 'moment';
import _ from 'lodash';
import { App } from '../App';
export class BaseModel {
    constructor(_fields = [], data = {}) {
        this._data = data;
        this._fields = _fields;
        this.pkName = 'id';
        this.initProperties();
        this._viewstate = {};
    }

    get pk() {
        return this.pkName ? this[this.pkName] : null;
    }

    initProperties() {
        const pkField = this._fields.find(o => o.isPk);
        if (pkField) {
            this.pkName = this._fields.find(o => o.isPk).name;
        } else {
            this.pkName = 'id';
        }
        this._fields.map(f => {
            if (!this.hasOwnProperty(f.name)) {
                Object.defineProperty(this, f.name, {
                    get: () => {
                        let value = this.parseToType(f);
                        return value;
                    },
                    set: (value) => {
                        if (this._data[f.name] !== value) {
                            this._data[f.name] = value;
                        }
                    }
                });
            }
        });
    }

    getViewState(name) {
        return this._viewstate[name];
    }

    setViewState(name, value) {
        this._viewstate[name] = value;
    }

    hasProperty(name) {
        return this.hasOwnProperty(name);
    }

    hasAttribute(name) {
        return this.attributes && this.attributes.hasOwnProperty(name);
    }


    getAttribute(name) {
        return this.attributes ? this.attributes[name] : null;
    }

    setAttribute(name, value) {
        if (!this.attributes) {
            this.attributes = {};
        }
        this.attributes[name]=value;
    }

    getProperty(name){
        return this[name];
    }


    getFormattedProperty(name) {
        const field = this._fields.find(o => o.name === name);
        if (field && field.format) {
            return field.format(this[name], this);
        }
        return App.AttributeFormatter.getFormatter(name)(this[name]);
    }
    getFormattedAttribute(name) {
        return App.AttributeFormatter.getAttributeFormatter(name)(this.getAttribute(name));
    }

    parseToType(f) {
        let value = this._data[f.name];
        // eslint-disable-next-line default-case
        switch (f.type) {
            case 'date':
                value = this._getDate(value, f);
                break;
            case 'int':
            case 'float':
            case 'double':
            case 'number':
            case 'integer':
                value = Number(value);

        }

        if (value === undefined || value === null) {
            if (f.default) {
                value = f.default;
            }
        }

        if (f.convert) {
            value = f.convert(value, this)
        }

        return value;
    }

    _getDate(value, field) {
        if (field.dateFormat === 'c') {
            return moment(value).valueOf();
        }
        return moment(value).toDate();
    }

    deserialize(input) {
        const clone = Object.assign({}, input);
        delete clone._localProps;
        Object.assign(this, clone);
        return this;
    }

    serialize() {
        return this._data;
    }

}