import React from 'react';
import { Button, Form, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { t } from '../../../utils/Translator';
import { Formik } from 'formik';
import * as yup from 'yup';
import { SessionService, AppService } from '../../../services';
import { connect } from 'react-redux';

class AccountPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attributesModal: false,
            sending: false,
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClose(modal) {
        this.setState({ [modal]: false })
        this.props.onHide();
    }

    async handleSubmit(data, { setSubmitting }) {
        try {
            const { user } = this.props;
            user.deserialize(data);
            await SessionService.updateUser(user);
            this.handleClose('accountModal');
        } catch (ex) {
            AppService.showError(ex);
        } finally {
            setSubmitting(false);
        }

    }

    componentWillReceiveProps(newProps, newState) {
        this.setState({ accountModal: newProps.currentModal === 'account' })
    }

    render() {
        const { user } = this.props;
        const { sending } = this.state;
        const schema = yup.object({
            name: yup.string().min(4).required(),
            email: yup.string().email().required(),
            phone: yup.string(),
            password: yup.string().min(4),
        });
        return (
            <div className="container-wrapper">
                <Formik
                    validationSchema={schema}
                    onSubmit={this.handleSubmit}
                    disabled={sending}
                    initialValues={{
                        name: user.name,
                        email: user.email,
                        password: '',
                        phone: user.phone
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        handleChange,
                        dirty,
                        isSubmitting,
                        errors,
                        isValid
                    }) => (

                            <Form onSubmit={handleSubmit} noValidate className="d-flex flex-column h-100 flex-grow-1 form form-sm ">
                                <React.Fragment>
                                    <div className="flex-grow-1 pt-3 overflow">
                                        <div className="container">
                                            <h3 className="internal-title"><i className="mdi mdi-account-circle"></i> {t('settingsUser')}</h3>

                                            <FormGroup>
                                                <FormLabel>{t("sharedName")}</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.name}
                                                    required />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("userEmail")}</FormLabel>
                                                <FormControl
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.email}
                                                    required />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("sharedPhone")}</FormLabel>
                                                <FormControl
                                                    name="phone"
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.phone}
                                                    required />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("userPassword")}</FormLabel>
                                                <FormControl
                                                    name="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.password} />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <footer className="footer">
                                        <div className="container">
                                            <Button variant="secondary" onClick={() => this.handleClose('accountModal')}>
                                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                                            </Button>
                                            <Button type="submit" variant="primary" disabled={!dirty || isSubmitting || !isValid}>
                                                <i className="mdi mdi-content-save"></i> {t('sharedSave')}
                                            </Button>
                                        </div>
                                    </footer>
                                </React.Fragment>
                            </Form>
                        )}
                </Formik>
            </div>
        )
    }
}




const mapStateToProps = (state) => ({
    user: state.session.user
})


export default connect(mapStateToProps, null)(AccountPage);