import React from 'react';
import { connect } from 'react-redux';
import { ListGroup, Button } from 'react-bootstrap';
import { t } from '../../../utils/Translator';
import { GroupsService } from '../../../services';
import EmptyList from '../../EmptyList';
import TextFilter from '../../Controls/TextFilter';
import CustomCheckbox from '../../Controls/CustomCheckBox';

export default class List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            selectedItems: props.selectedItems || [],
            allSelected: false,
        }
        this.deleteItem = this.deleteItem.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.toggleItem = this.toggleItem.bind(this);
        this.toggleAll = this.toggleAll.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.unselectAll = this.unselectAll.bind(this);
    }



    deleteItem(id) {
        if (this.props.onDelete) {
            this.props.onDelete(id);
        }
    }

    selectItem(id) {
        this.toggleItem(id, () => {
            if (this.props.onSelect) {
                this.props.onSelect(id, this.isItemSelected(id));
            }
        });

    }

    setFilter(value) {
        if (value != this.state.filter) {
            this.setState({ filter: value })
        }
    }

    filterValues(values) {
        if (this.state.filter && this.state.filter != '') {
            return Object.values(values).filter(o => o && o.name.toLowerCase().includes(this.state.filter.toLowerCase()))
        }
        return Object.values(values)
    }

    toggleItem(id, callback) {
        const selectionMode = this.props.selectionMode != undefined ? this.props.selectionMode : 'multiple';
        if (selectionMode !== 'multiple') {
            this.setState({ selectedItems: [] });
        }
        let selectedItems = this.state.selectedItems;
        if (this.isItemSelected(id)) {
            selectedItems = selectedItems.filter(o => o != id);
        } else {
            selectedItems.push(id);
        }
        this.setState({ selectedItems: selectedItems }, callback);
    }

    isItemSelected(id) {
        return (this.state.selectedItems ? this.state.selectedItems.includes(id) : false);
    }

    
    selectAll() {
        const { items } = this.props;
        const values = this.filterValues(items).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        const selectedItems = [];
        values.map(v => {
            selectedItems.push(v.id);
        });
        this.setState({ selectedItems: selectedItems, allSelected: true }, () => {
            selectedItems.map(id => {
                this.props.onSelect(id, this.isItemSelected(id));
            })
        });
    }

    unselectAll() {
        const { items } = this.props;
        const values = this.filterValues(items).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        const selectedItems = [];
        values.map(v => {
            selectedItems.push(v.id);
        });
        this.setState({ selectedItems: [], allSelected: false }, () => {
            selectedItems.map(id => {
                this.props.onSelect(id, this.isItemSelected(id));
            })
        });
    }

    toggleAll() {
        if (this.state.allSelected) {
            this.unselectAll();
        } else {
            this.selectAll();
        }
    }

    render() {
        const { items } = this.props;
        const values = this.filterValues(items).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        const enableFilter = this.props.enableFilter != undefined ? this.props.enableFilter : true;
        const enableDelete = this.props.enableDelete != undefined ? this.props.enableDelete : false;
        const enableSelection = this.props.enableSelection != undefined ? this.props.enableSelection : false;


        return <React.Fragment>
            <h3 className="internal-title"><i className="mdi mdi-google-circles-extended"></i> {t('settingsGroups')}</h3>
            <div className="list-tools">
                {enableSelection ?
                    <div className="list-controls">
                        <CustomCheckbox checked={this.state.allSelected} onChange={() => this.toggleAll()} />
                    </div>
                    : null}
                {enableFilter ? <React.Fragment><TextFilter onFilter={(value) => this.setFilter(value)} />
                </React.Fragment> : null}
                <hr />
            </div>
            {values.length > 0 ? <ListGroup >
                {values.map(i => {
                    if (i.id > 0) {
                        return <ListGroup.Item className="list-group-item list-group-item-action border-1" key={i.pk}>
                            {enableSelection ?
                                <CustomCheckbox checked={this.isItemSelected(i.pk)} onChange={() => this.selectItem(i.pk)} />
                                : null}
                            <div className="content" onClick={() => this.selectItem(i.pk)}>
                                <strong className="name">{i.name}</strong>
                                <span className="value">

                                </span>
                            </div>
                            {enableDelete ? <div className="actions">
                                <Button variant="default" className="danger" onClick={() => this.deleteItem(i.pk)}><i className="mdi mdi-delete"></i></Button>
                            </div> : null}
                        </ListGroup.Item>
                    }
                    return null;
                })}
            </ListGroup> : <EmptyList />}
        </React.Fragment>
    }

}
