import L from 'leaflet';
import 'leaflet.markercluster';
import LeafletGeofence from './LeafletGeofence';
import { store } from '../../../store';
import BaseMap from '../base/BaseMap';
import constants from '../../../config/constants';
import LeafletMarker from './LeafletMarker';
import 'overlapping-marker-spiderfier-leaflet';
//import 'leaflet-canvas-marker';
import omnivore from '@mapbox/leaflet-omnivore';
import { App } from '../../../App';
import LeafletMainMap from './LeafletMainMap';
import { t } from '../../Translator';
import Events from '../../Events';

var CustomMarker = L.CircleMarker.extend({
    _updatePath: function () {
        this._renderer._updateMarker6Point(this);
    }
});

const ArrowMarker = L.CircleMarker.extend({
    initialize: function (latLng, heading, options) {
        this._heading = heading;
        L.CircleMarker.prototype.initialize.call(this, latLng, options);
    },

    setHeading: function (heading) {
        this._heading = heading;
        this.redraw();
    },

    getPathString: function () {
        var p = this._point,
            r = this._radius;

        if (this._checkIfEmpty()) {
            return '';
        }

        return 'M0,' + (-r - 1) + ' ' +
            'L-3,' + (-r) + ' ' +
            'L0,' + (-r - 8) + ' ' +
            'L3,' + (-r) + ' z';
    },

});



export default class LeafletReportsMap extends BaseMap {
    constructor() {
        super();
        this.markers = {};
        this.selectedMarker = null;
        this.geofences = {};
        this.routes = {};
        this.reportType = null;
        this._selectedMarkerId = null;
        this._overlappingLayer = null;
        this.onMapClick = null;
    }

    destroy() {
        if (this.map) {
            this.map.off();
            this.map.remove();
        }
    }

    _initMap(target, config = {}) {

        const scaleLine = new L.Control.Scale({
            position: 'bottomright'
        });

        this.config = config;

        let mapUrl = config.map_config && config.map_config.url ? config.map_config.url : store.getState().app.mapstate.map_config.url;
        const subdomains = config.map_config && config.map_config.subdomains ? config.map_config.subdomains : store.getState().app.mapstate.map_config.subdomains;
        const lat = config.map_config && config.map_config.latitude ? config.map_config.latitude : store.getState().app.mapstate.map_config.latitude;
        const lon = config.map_config && config.map_config.longitude ? config.map_config.longitude : store.getState().app.mapstate.map_config.longitude;
        const zoom = config.map_config && config.map_config.zoom ? config.map_config.zoom : store.getState().app.mapstate.map_config.zoom;

        const maxZoom = App.App.getAttributePreference('web.maxZoom', config.map_config && config.map_config.maxZoom ? config.map_config.maxZoom : store.getState().app.mapstate.map_config.maxZoom);

        this.tileLayer = new L.TileLayer(mapUrl.replace(/&amp;/g, '&'), {
            attribution: '',
            subdomains: subdomains
        });

        this.map = new L.Map(target, {
            center: [lat, lon],
            zoom: zoom,
            maxZoom: maxZoom,
            attributionControl: false,
            zoomControl: false,
            layers: [
                this.tileLayer,
            ]
        });

        this._displayNames = this.config.options.show_names;
        this._displayGeofences = this.config.options.show_geofences;
        this._displayRoutes = this.config.options.show_routes;
        this._clusterEnabled = this.config.options.cluster_enabled;
        this._followDevices = this.config.options.follow_devices;


        scaleLine.addTo(this.map);


        this._loadPoiLayer();

        this._initGeofencesContainerLayer();
        this._initRoutesContainerLayer();
        this._initMarkersContainerLayer(this._clusterEnabled);

        this.routesContainerLayer.bringToBack();
        this.geofencesContainerLayer.bringToBack();

        if (this.onMapClick) {
            this.map.on('click', (e) => {
                if (!e.parsed) {
                    this.onMapClick(e);
                }

            })
        }

        /*this.markersContainerLayer.addOnClickListener((e, data) => {
            e.parsed = true;

            if (data.length > 0) {
                Events.emit('reports/SELECT_POSITION', data[0].data.id)
            } else {
                Events.emit('reports/UNSELECT_POSITION')
            }
        });*/



        /*this.markersContainerLayer.addOnClickListener((e) => {
            let clickBounds = L.latLngBounds(e.latlng, e.latlng);
            var intersectingFeatures = [];


            Object.values(this.markers).map(l => {
                const feature = l.marker;
                if (this.selectedMarker != feature) {
                    const bounds = feature.getLatLng().toBounds(20);
                    if (bounds && clickBounds.intersects(bounds)) {
                        intersectingFeatures.push(feature);
                    }
                }
            })

          
            if (intersectingFeatures.length > 0) {
                if (this.selectedMarker) {
                    this.map.removeLayer(this.selectedMarker);
                    this.markersContainerLayer.addMarker(this.selectedMarker);
                }
                const m = intersectingFeatures[0];
                this.selectedMarker = m;
                this.markersContainerLayer.removeMarker(m);
                this.map.addLayer(m);
                this.selectedMarker.on('click', () => {
                    this.map.removeLayer(this.selectedMarker);
                    this.markersContainerLayer.addMarker(this.selectedMarker);
                    this.selectedMarker.off('click');
                })
            }
        });*/


        return this.map;

    }

    _initMarkersContainerLayer(clustered = true) {
        if (this.markersContainerLayer) {
            this.markersContainerLayer.clearLayers();
            //this.markersContainerLayer.remove();
        }
        //this.markersContainerLayer = new L.MarkerClusterGroup({});
        //this.markersContainerLayer = new L.LayerGroup();
        this.markersContainerLayer = new L.MarkerClusterGroup({
            renderer: new L.Canvas(),
            chunkedLoading: true,
            animate: false,
            animateAddingMarkers: false,
            disableClusteringAtZoom: this.map.getMaxZoom() - 4,
            spiderfyOnMaxZoom: true,
            showCoverageOnHover: true,
            zoomToBoundsOnClick: true,
            removeOutsideVisibleBounds: false,
            spiderLegPolylineOptions: true
        });
        this.markersContainerLayer.addTo(this.map);
    }



    _loadPoiLayer() {
        const poiLayer = App.App.getPreference('poiLayer', null);

        if (poiLayer) {
            omnivore.kml(poiLayer).addTo(this.map);
        }

    }


    updateGeofences(data) {
        this.geofencesContainerLayer.clearLayers();
        if (data.constructor !== Array) {
            data = [data];
        }
        data.map(geofence => {
            if (geofence) {
                this._updateGeofence(geofence)
            }
        });
    }



    _updateGeofence(geofence) {
        let feature = this.getGeofence(geofence.id);
        if (!feature) {
            feature = new LeafletGeofence(geofence, this.geofencesContainerLayer);
            feature.init();
            feature.addToParent();
        }
    }

    _initRoutesContainerLayer() {
        if (this.routesContainerLayer) {
            this.routesContainerLayer.remove();
        }
        this.routesContainerLayer = new L.FeatureGroup();
        this.routesContainerLayer.addTo(this.map);
    }


    _initGeofencesContainerLayer() {
        if (this.geofencesContainerLayer) {
            this.geofencesContainerLayer.remove();
        }
        this.geofencesContainerLayer = new L.FeatureGroup();
        if (this._displayGeofences) {
            this.geofencesContainerLayer.addTo(this.map);
        }
    }

    /**
     * clear the map
     */
    clear() {
        /*this.markers = {};
        this.geofences = {};
        this.routes = {};
        if (this._overlappingLayer) {
            this._overlappingLayer.clearMarkers();
        }
        this.clearMarkers();
        this.geofencesContainerLayer.clearLayers();
        this.routesContainerLayer.clearLayers();*/
    }


    getColorOfDevice(device, returnDefault = true) {
        return App.App.getReportColor(device.id, returnDefault);
    }


    _constructMarkerConfig(device, position, selected = false) {
        console.log(this.getColorOfDevice(device, true));
        return {
            statusColor: this.getColorOfDevice(device, true),
            iconColor: this.getColorOfDevice(device, true),
            lat: position ? position.latitude : 0,
            lng: position ? position.longitude : 0,
            category: 'arrow',
            rotation: position ? position.course : 0,
            selected: selected,
            title: device.name,
            id: position.id,
            hidden: device.hidden,
        }
    }

    getPositionAttributes(device, position) {
        try {
            const hideAttributesPreference = App.App.getAttributePreference('ui.hidePositionAttributes');
            const hideAttributes = {};
            if (hideAttributesPreference) {
                const attributesList = hideAttributesPreference.split(/[ ,]+/).filter(Boolean);
                attributesList.map(v => {
                    hideAttributes[v] = true;
                })
            }


            const ret = (() => {
                const list = constants.position_attributes;
                const result = {};
                list.map(a => {
                    result[a] = {
                        name: t('position' + a.replace(/^\w/g, function (s) {
                            return s.toUpperCase();
                        })),
                        key: a,
                        value: position.hasOwnProperty(a) ? position.getFormattedProperty(a) : undefined
                    };
                })
                return result;
            })();


            const positionAttributes = position.attributes;
            if (positionAttributes instanceof Object) {
                Object.keys(positionAttributes).map(key => {
                    if (positionAttributes.hasOwnProperty(key) && !hideAttributes[key]) {
                        let value = position.hasAttribute(key) ? App.AttributeFormatter.getAttributeFormatter(key)(position.getAttribute(key)) : App.AttributeFormatter.defaultFormatter(positionAttributes[key]);
                        if (key === 'alarm' && value) {
                            value = t('alarm' + value.ucFirst());
                        } else if (key === 'event') {
                            //value = App.App.getEventString(value);
                        }
                        //console.log(key, value);
                        ret[key] = ({
                            key: key,
                            name: App.PositionAttributes.getAttributeName(key),
                            value: value
                        })
                    }
                })
            };

            let str = "";
            Object.values(ret).map(a => {
                str += `<tr><th>${a.name}</th><td>${a.value}</td></tr>`;

            })

            return str;
        } catch (ex) {
            console.log(ex);
        }
        return "";
    }

    createPopup(device, position) {
        return `<table class="map-popup">
            <tr>
                <th class="title">${device.name}</th>
            </tr>
            <tr>
            <td>
            <div class="attributes">
            <table width="100%">
            ${this.getPositionAttributes(device, position)}
            </table>
            </div>
            </td>
            </tr>
        </table>`;
    }

    updatePositions(data) {
        if (Object.values(this.markers).length > 0) {
            this.clearMarkers();
        }

        this.data = data;
        this.routesContainerLayer.bringToBack();
        //this.markersContainerLayer.bringToFront();
        this.clearRoutes();
        const bounds = new L.LatLngBounds();

        Object.keys(data.data).map(groupId => {
            const positions = data.data[groupId];
            const device = store.getState().devices.devices[groupId];
            if (device) {
                const points = [];
                const markers = [];
                positions.map(p => {
                    markers.push(this.addMarker(device, p).marker);
                    points.push([p.latitude, p.longitude]);
                    bounds.extend([p.latitude, p.longitude]);

                })
                if (data.config.showMarkers) {
                    this.markersContainerLayer.addLayers(markers);
                }
                if (data.config.showRoutes) {
                    //routes
                    const mapRouteWidth = App.App.getAttributePreference('web.liveRouteLength', constants.mapRouteWidth);
                    const route = new L.Polyline(points, { name: groupId + '_route' });
                    route.bindTooltip(device.name, { direction: 'bottom', sticky: true });
                    route.setStyle({
                        color: this.getColorOfDevice(device, true),
                        weight: mapRouteWidth,
                    })
                    this.routes[groupId] = route;
                    this.routesContainerLayer.addLayer(route);
                }
            }
        });
        //setTimeout(() => {

        this.routesContainerLayer.bringToBack();
        this.geofencesContainerLayer.bringToBack();
        if (bounds.isValid()) {
            this.map.fitBounds(bounds);
        }
        this.invalidateSize();
        //}, 1000);
    }

    addMarker(device, position) {
        const config = this._constructMarkerConfig(device, position);
        const marker = new LeafletMarker();
        marker.create(config);
        marker.marker.info = {
            device: device,
            position: position
        };
        marker.marker.bindPopup().on('popupopen', (e) => {
            const popup = e.target.getPopup();
            popup.setContent(this.createPopup(e.target.info.device, e.target.info.position));
        })

        marker.marker.on('click', (e) => {
            e.parsed = true;
            Events.emit('reports/SELECT_POSITION', e.target.id)
        })

        marker.marker.id = config.id;
        marker.id = config.id;
        marker.marker.unbindTooltip();
        this.markers[config.id] = marker;
        return marker;
    }


    getMarker(id) {
        return this.markers[id];
    }

    getRoute(id) {
        return this.routes[id];
    }

    getGeofence(id) {
        return this.geofences[id];
    }


    clearMarkers() {
        try {
            if (Object.values(this.markers).length > 0) {
                this._initMarkersContainerLayer();

            }
        } catch (ex) {

        } finally {
            this.markers = {};
        }
    }


    clearRoutes() {
        this.routes = {};
        this.routesContainerLayer.clearLayers();
    }


    invalidateSize() {
        try {
            this.map.invalidateSize();
        } catch (ex) {

        }

    }

    centerToPoint(lat, lng) {
        const desiredZoom = App.App.getAttributePreference('web.selectZoom', constants.selectedZoom);
        const zoom = (this.map.getZoom() > desiredZoom) ? this.map.getZoom() : desiredZoom;

        this.map.setView([lat, lng], zoom, {
            pan: {
                animate: false,
                duration: .5
            },
            zoom: {
                animate: false
            }
        });
    }

    centerToMarker(marker) {
        this.centerToPoint(marker.marker.info.position.latitude, marker.marker.info.position.longitude);
    }

    updateMarker(id, data) {
        const marker = this.markers[id];
        if (marker) {
            marker.marker.info.position = data;
            if (this.selectedMarker && this.selectedMarker.id === id) {
                this.markers[id].marker.bindPopup(new L.Popup({
                    autoClose: false
                }).setContent(this.createPopup(this.markers[id].marker.info.device, this.markers[id].marker.info.position))).openPopup();
            }
        }
    }

    selectMarker(id) {
        this.unselectMarker();

        if (!this.markers[id]) {

        }

        if (this.markers[id]) {

            this.selectedMarker = this.markers[id];
            this.selectedMarker.marker.addTo(this.map);

            //this.markers[id].marker.openPopup();
            this.centerToMarker(this.markers[id]);
            this.selectedMarker.update(this._constructMarkerConfig(this.selectedMarker.marker.info.device, this.selectedMarker.marker.info.position, true));
            this.markersContainerLayer.refreshClusters();
            this.markers[id].marker.bindPopup(new L.Popup({
                autoClose: false
            }).setContent(this.createPopup(this.markers[id].marker.info.device, this.markers[id].marker.info.position))).openPopup();
            //this.map.removeLayer(this.markersContainerLayer);
            //this.map.addLayer(this.markersContainerLayer);
        }

    }

    unselectMarker() {
        if (this.selectedMarker) {
            this.selectedMarker.update(this._constructMarkerConfig(this.selectedMarker.marker.info.device, this.selectedMarker.marker.info.position, false));
            this.map.removeLayer(this.selectedMarker.marker);
            if (this.data.showMarkers) {
                this.markersContainerLayer.refreshClusters();
                this.markersContainerLayer.addLayer(this.selectedMarker.marker);
                //this.map.removeLayer(this.markersContainerLayer);
                //this.map.addLayer(this.markersContainerLayer);
                this.selectedMarker = null;
                this.map.setView(this.map.getCenter(), this.map.getZoom() - 1, {
                    "animate": false,
                });
                this.map.setView(this.map.getCenter(), this.map.getZoom() + 1, {
                    "animate": false,
                });
            }
            //this.markersContainerLayer.redraw();
        }
    }
}