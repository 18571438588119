import React from "react";
import AttributesPage from "../Attributes";
import {
  Row,
  Button,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  InputGroup
} from "react-bootstrap";
import { t } from "../../../utils/Translator";
import { Formik } from "formik";
import * as yup from "yup";
import { User } from "../../../models";
import { deepClone, isEqual } from "../../../utils/functions";
import CustomSelect from "../../Controls/CustomSelect";
import CustomCheckbox from "../../Controls/CustomCheckBox";
import { store } from "../../../store";
import constants from "../../../config/constants";
import Collapse from "../../Collapse";
import Datetime from "react-datetime";
import PermissionsToolbar from "../Permissions/toolbar";
import { App } from "../../../App";

export default class UserForm extends React.Component {
  constructor(props) {
    super(props);

    const mapOptions = [];
    Object.keys(constants.mapTypes[constants.selectedMapType].mapServices).map(
      i => {
        const c = constants.mapTypes[constants.selectedMapType].mapServices[i];
        if (c.enabled) {
          mapOptions.push({ label: t(c.name), value: i, config: c });
        }
      }
    );

    const coordinateFormats = [];
    const coordinate_formats = store.getState().coordinate_formats
      .coordinate_formats;
    Object.values(coordinate_formats).map(o => {
      coordinateFormats.push({ label: t(o.name), value: o.key });
    });

    this.state = {
      attributesPage: false,
      item: new User().deserialize(deepClone(props.item)),
      mode: props.mode,
      sending: false,
      changed: false,
      mapOptions: mapOptions,
      coordinateFormats: coordinateFormats
    };

    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.openAttributesPage = this.openAttributesPage.bind(this);
    this.onAttributesCancel = this.onAttributesCancel.bind(this);
    this.onAttributesClosed = this.onAttributesClosed.bind(this);
  }

  save(values) {
    const item = this.state.item.deserialize(values);
    this.props.onSave(item);
  }

  cancel() {
    this.props.onHide();
  }

  openAttributesPage() {
    this.setState({ attributesPage: true });
  }

  onAttributesCancel() {
    const clone = new User().deserialize(deepClone(this.props.item));
    const item = this.state.item;
    item.attributes = clone.attributes;
    this.setState({
      ...this.state,
      item,
      attributesPage: false
    });
  }

  onAttributesClosed() {
    this.setState({
      ...this.state,
      attributesPage: false,
      changed: !isEqual(this.state.item.attributes, this.props.item.attributes)
    });
  }

  componentWillReceiveProps(newProps, newState) {
    this.setState({ item: new User().deserialize(deepClone(newProps.item)) });
  }

  getMapState(setFieldValue) {
    const state = store.getState().app.mapstate;
    setFieldValue("zoom", state.zoom);
    setFieldValue("latitude", state.latitude);
    setFieldValue("longitude", state.longitude);
  }

  render() {
    const { sending, coordinateFormats, mapOptions, item } = this.state;

    const schema = yup.object({
      name: yup
        .string()
        .min(4)
        .required(),
      email: yup
        .string()
        .email()
        .required(),
      password: yup.string().min(4),
      phone: yup.string().optional(),
      mapLayer: yup.string(),
      latitude: yup.number(),
      longitude: yup.number(),
      zoom: yup
        .number()
        .min(1)
        .optional(),
      twelveHourFormat: yup.bool(),
      coordinateFormat: yup.string(),
      poiLayer: yup.string().optional()
    });
    return (
      <React.Fragment>
        {this.state.attributesPage ? (
          <AttributesPage
            item={item}
            type="usersAttributes"
            onHide={() => this.onAttributesClosed()}
            onSave={() => this.onAttributesClosed()}
            onCancel={() => this.onAttributesCancel()}
          />
        ) : null}

        <div className="container-wrapper">
          <Formik
            validationSchema={schema}
            onSubmit={this.save}
            disabled={sending}
            initialValues={{
              name: item.name,
              email: item.email,
              password: "",
              phone: item.phone,
              mapLayer: item.map || mapOptions[0].value,
              latitude: item.latitude || constants.mapDefaultLat,
              longitude: item.longitude || constants.mapDefaultLon,
              zoom: item.zoom || constants.mapDefaultZoom,
              twelveHourFormat: item.twelveHourFormat
                ? item.twelveHourFormat
                : false,
              coordinateFormat:
                item.coordinateFormat || coordinateFormats[0].value,
              poiLayer: item.poiLayer || "",
              disabled: item.disabled,
              administrator: item.administrator,
              readonly: item.readonly,
              deviceReadonly: item.deviceReadonly,
              limitCommands: item.limitCommands,
              expiration: item.expiration,
              deviceLimit: item.deviceLimit ? item.deviceLimit : 0,
              userLimit: item.userLimit ? item.userLimit : 0,
              deactive_attributes: item.deactive_attributes,
              deactive_calendars: item.deactive_calendars,
              deactive_drivers: item.deactive_drivers,
              deactive_geofences: item.deactive_geofences,
              deactive_groups: item.deactive_groups,
              deactive_maintenance: item.deactive_maintenance
            }}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              dirty,
              isSubmitting,
              errors,
              isValid,
              setFieldValue
            }) => (
              <Form
                onSubmit={handleSubmit}
                noValidate
                className="d-flex flex-column h-100 flex-grow-1 form form-sm "
              >
                <React.Fragment>
                  <div className="flex-grow-1 pt-3 overflow">
                    <div className="container">
                      <h3 className="internal-title">
                        <i className="mdi mdi mdi-account-multiple"></i>{" "}
                        {this.state.item.id ? t("sharedEdit") : t("sharedAdd")}{" "}
                        {t("settingsUsers")}
                      </h3>
                      {this.state.item.id ? (
                        <PermissionsToolbar item={this.state.item} />
                      ) : null}

                      <FormGroup>
                        <FormLabel>{t("sharedName")}</FormLabel>
                        <FormControl
                          type="text"
                          name="name"
                          defaultValue={values.name}
                          onChange={handleChange}
                          isInvalid={!!errors.name}
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormLabel>{t("userEmail")}</FormLabel>
                        <FormControl
                          name="email"
                          defaultValue={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormLabel>{t("sharedPhone")}</FormLabel>
                        <FormControl
                          name="phone"
                          defaultValue={values.phone}
                          onChange={handleChange}
                          isInvalid={!!errors.phone}
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormLabel>{t("userPassword")}</FormLabel>
                        <FormControl
                          name="password"
                          defaultValue={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                        />
                      </FormGroup>

                      <hr />

                      <FormGroup style={{ zIndex: 1 }}>
                        <FormLabel>{t("mapLayer")}</FormLabel>
                        <CustomSelect
                          options={mapOptions}
                          onChange={setFieldValue}
                          defaultValue={values.mapLayer}
                          name="map"
                        />
                      </FormGroup>
                      <Row>
                        <FormGroup className="col-4">
                          <FormLabel>{t("positionLatitude")}</FormLabel>
                          <FormControl
                            type="number"
                            name="latitude"
                            value={values.latitude}
                            onChange={handleChange}
                            isInvalid={!!errors.latitude}
                          />
                        </FormGroup>
                        <FormGroup className="col-4">
                          <FormLabel>{t("positionLongitude")}</FormLabel>
                          <FormControl
                            type="number"
                            name="longitude"
                            value={values.longitude}
                            onChange={handleChange}
                            isInvalid={!!errors.longitude}
                          />
                        </FormGroup>
                        <FormGroup className="col-4">
                          <FormLabel>{t("serverZoom")}</FormLabel>
                          <InputGroup>
                            <FormControl
                              type="number"
                              name="zoom"
                              min="1"
                              className="d-inline-block"
                              value={values.zoom}
                              onChange={handleChange}
                              isInvalid={!!errors.zoom}
                            />
                            <InputGroup.Append>
                              <Button
                                style={{ zIndex: 0 }}
                                onClick={() => this.getMapState(setFieldValue)}
                                title={t("sharedGetMapState")}
                              >
                                <i className="mdi mdi-map-marker"></i>
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup className="col-12 col-md-6">
                          <FormLabel>{t("settingsCoordinateFormat")}</FormLabel>
                          <CustomSelect
                            options={coordinateFormats}
                            onChange={setFieldValue}
                            defaultValue={values.coordinateFormat}
                            name="coordinateFormat"
                          />
                        </FormGroup>
                        <FormGroup className="col-12 col-md-6">
                          <FormLabel>{t("mapPoiLayer")}</FormLabel>
                          <FormControl
                            type="string"
                            name="poiLayer"
                            defaultValue={values.poiLayer}
                            onChange={handleChange}
                            isInvalid={!!errors.poiLayer}
                          />
                        </FormGroup>
                      </Row>
                      <FormGroup>
                        <CustomCheckbox
                          name="twelveHourFormat"
                          defaultValue="twelveHourFormat"
                          checked={values.twelveHourFormat}
                          onChange={handleChange}
                          label={t("settingsTwelveHourFormat")}
                        />
                      </FormGroup>

                      {App.App.user.id !== this.state.item.id ||
                      App.App.user.is_super_admin === 1 ? (
                        <div className="mt-3">
                          <Collapse
                            title={
                              <h5 className="m-0">{t("sharedPermissions")}</h5>
                            }
                          >
                            <div className="clearfix">
                              <Row>
                                <FormGroup className="col-12 col-sm-4">
                                  <FormLabel>
                                    {t("userExpirationTime")}
                                  </FormLabel>
                                  <Datetime
                                    timeFormat={false}
                                    dateFormat="YYYY-MM-DD"
                                    name="expiration"
                                    defaultValue={values.expiration}
                                    onChange={handleChange}
                                  >
                                    {" "}
                                  </Datetime>
                                </FormGroup>
                              </Row>
                              {App.App.user.administrator ? (
                                <Row>
                                  <FormGroup className="col-12 col-sm-4">
                                    <FormLabel>
                                      {t("userDeviceLimit")}
                                    </FormLabel>
                                    <FormControl
                                      type="number"
                                      name="deviceLimit"
                                      defaultValue={values.deviceLimit}
                                      onChange={handleChange}
                                      isInvalid={!!errors.deviceLimit}
                                    />
                                  </FormGroup>
                                </Row>
                              ) : null}
                              {App.App.user.administrator ? (
                                <Row>
                                  <FormGroup className="col-12 col-sm-4">
                                    <FormLabel>{t("userUserLimit")}</FormLabel>
                                    <FormControl
                                      type="number"
                                      name="userLimit"
                                      defaultValue={values.userLimit}
                                      onChange={handleChange}
                                      isInvalid={!!errors.userLimit}
                                    />
                                  </FormGroup>
                                </Row>
                              ) : null}

                              <Row>
                                 <FormGroup className="col-4">
                                  <CustomCheckbox
                                    name="administrator"
                                    defaultValue="administrator"
                                    defaultChecked={values.administrator}
                                      onChange={handleChange}
                                      label={t("userAdmin")}
                                  />
                                </FormGroup>
                                <FormGroup className="col-4">
                                  <CustomCheckbox
                                    name="disabled"
                                    defaultValue="disabled"
                                    defaultChecked={values.disabled}
                                    onChange={handleChange}
                                    label={t("sharedDisabled")}
                                  />
                                </FormGroup>
                                {App.App.user.administrator&& App.App.user.is_super_admin===1? (
                                  <FormGroup className="col-4">
                                    <CustomCheckbox
                                      name="administrator"
                                      defaultValue="administrator"
                                      defaultChecked={values.administrator}
                                      onChange={handleChange}
                                      label={t("userAdmin")}
                                    />
                                  </FormGroup>
                                ) : null}

                                <FormGroup className="col-4">
                                  <CustomCheckbox
                                    name="readonly"
                                    defaultValue="readonly"
                                    defaultChecked={values.readonly}
                                    onChange={handleChange}
                                    label={t("serverReadonly")}
                                  />
                                </FormGroup>
                            
                              </Row>

                              <Row>
                               
                                <FormGroup className="col-4">
                                  <CustomCheckbox
                                    name="deactive_attributes"
                                    defaultValue="deactive_attributes"
                                    defaultChecked={values.deactive_attributes}
                                    onChange={handleChange}
                                    label={t("attributeUiDisableComputedAttributes")}
                                  />
                                </FormGroup>
                                <FormGroup className="col-4">
                                  <CustomCheckbox
                                    name="deactive_calendars"
                                    defaultValue="deactive_calendars"
                                    defaultChecked={values.deactive_calendars}
                                    onChange={handleChange}
                                    label={t("attributeUiDisableCalendars")}
                                  />
                                </FormGroup>

                                <FormGroup className="col-4">
                                  <CustomCheckbox
                                    name="deviceReadonly"
                                    defaultValue="deviceReadonly"
                                    defaultChecked={values.deviceReadonly}
                                    onChange={handleChange}
                                    label={t("userDeviceReadonly")}
                                  />
                                </FormGroup>
                              </Row>

                              <Row>
                                <FormGroup className="col-4">
                                  <CustomCheckbox
                                    name="deactive_drivers"
                                    defaultValue="deactive_drivers"
                                    defaultChecked={values.deactive_drivers}
                                    onChange={handleChange}
                                    label={t("attributeUiDisableDrivers")}
                                  />
                                </FormGroup>

                                <FormGroup className="col-4">
                                  <CustomCheckbox
                                    name="deactive_geofences"
                                    defaultValue="deactive_geofences"
                                    defaultChecked={values.deactive_geofences}
                                    onChange={handleChange}
                                    label={
                                      t("UI:Disable") +
                                      " " +
                                      t("sharedGeofence")
                                    }
                                  />
                                </FormGroup>
                                <FormGroup className="col-4">
                                  <CustomCheckbox
                                    name="deactive_groups"
                                    defaultValue="deactive_groups"
                                    defaultChecked={values.deactive_groups}
                                    onChange={handleChange}
                                    label={
                                      t("UI:Disable") + " " + t("groupParent")
                                    }
                                  />
                                </FormGroup>
                              </Row>

                              <Row>
                                <FormGroup className="col-4">
                                  <CustomCheckbox
                                    name="deactive_maintenance"
                                    defaultValue="deactive_maintenance"
                                    defaultChecked={values.deactive_maintenance}
                                    onChange={handleChange}
                                    label={t("attributeUiDisableMaintenance")}
                                  />
                                </FormGroup>

                                <FormGroup className="col-4">
                                  <CustomCheckbox
                                    name="limitCommands"
                                    defaultValue="limitCommands"
                                    defaultChecked={values.limitCommands}
                                    onChange={handleChange}
                                    label={t("userLimitCommands")}
                                  />
                                </FormGroup>
                              </Row>
                            </div>
                          </Collapse>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <footer className="footer">
                    <div className="container d-flex">
                      <div className="flex-grow-1 d-flex">
                        <Button
                          variant="secondary"
                          className="align-self-stretch"
                          onClick={() => this.openAttributesPage()}
                        >
                          <i className="mdi mdi-playlist-play"></i>{" "}
                          {t("sharedAttributes")}
                        </Button>
                      </div>
                      <Button
                        variant="secondary"
                        onClick={() => this.props.onHide()}
                      >
                        <i className="mdi mdi-close"></i> {t("sharedCancel")}
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={
                          !this.state.changed &&
                          (!dirty || isSubmitting || !isValid)
                        }
                      >
                        <i className="mdi mdi-content-save"></i>{" "}
                        {t("sharedSave")}
                      </Button>
                    </div>
                  </footer>
                </React.Fragment>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}
