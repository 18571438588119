import React from 'react';
import AttributesPage from '../Attributes';
import { Button, Form, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { t } from '../../../utils/Translator';
import { Formik } from 'formik';
import * as yup from 'yup';
import { store } from '../../../store';
import { Maintenance } from '../../../models';
import { deepClone, isEqual } from '../../../utils/functions';
import CustomNumberField from '../../Controls/CustomNumberField';
import CustomSelect from '../../Controls/CustomSelect';


export default class MaintenanceForm extends React.Component {
    constructor(props) {
        super(props);

        const typeOptions = [];
        Object.values(store.getState().maintenance_types.maintenance_types).map(o => {
            typeOptions.push({ label: t(o.name), value: o.key })
        });
        typeOptions.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)

        this.state = {
            attributesPage: false,
            item: new Maintenance().deserialize(deepClone(props.item)),
            mode: props.mode,
            sending: false,
            changed: false,
            typeOptions: typeOptions
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.openAttributesPage = this.openAttributesPage.bind(this);
        this.onAttributesCancel = this.onAttributesCancel.bind(this);
        this.onAttributesClosed = this.onAttributesClosed.bind(this);
    }


    save(values) {
        const item = this.state.item.deserialize(values);
        this.props.onSave(item);
    }

    cancel() {
        this.props.onHide();
    }

    openAttributesPage() {
        this.setState({ attributesPage: true })
    }

    onAttributesCancel() {
        const clone = new Maintenance().deserialize(deepClone(this.props.item));
        const item = this.state.item;
        item.attributes = clone.attributes;
        this.setState({
            ...this.state,
            item,
            attributesPage: false
        })
    }

    onAttributesClosed() {
        this.setState({
            ...this.state,
            attributesPage: false,
            changed: !isEqual(this.state.item.attributes, this.props.item.attributes)
        })
    }


    componentWillReceiveProps(newProps, newState) {
        this.setState({ item: new Maintenance().deserialize(deepClone(newProps.item)) })
    }



    render() {
        const { sending, item, typeOptions } = this.state;

        const schema = yup.object({
            name: yup.string().required(),
            type: yup.string().required(),
            start: yup.number().min(0),
            period: yup.number().min(0.1)
        });


        return <React.Fragment>
            {this.state.attributesPage ?
                <AttributesPage item={item} type="userAttributes" onHide={() => this.onAttributesClosed()} onSave={() => this.onAttributesClosed()} onCancel={() => this.onAttributesCancel()} />
                : null}

            <div className="container-wrapper">
                <Formik
                    validationSchema={schema}
                    onSubmit={this.save}
                    disabled={sending}
                    initialValues={{
                        name: item.name,
                        period: item.period || 0,
                        start: item.start || 0,
                        type: item.type || ''
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        handleChange,
                        dirty,
                        isSubmitting,
                        setFieldValue,
                        errors,
                        isValid }) => {
                        return (

                            <Form onSubmit={handleSubmit} noValidate className="d-flex flex-column h-100 flex-grow-1 form form-sm ">
                                <React.Fragment>
                                    <div className="flex-grow-1 pt-3 overflow">
                                        <div className="container ">
                                            <h3 className="internal-title"><i className="mdi mdi-wrench"></i> {this.state.item.id ? t('sharedEdit') : t('sharedAdd')} {t('sharedMaintenance')}</h3>
                                            <FormGroup>
                                                <FormLabel>{t("sharedName")}</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="name"
                                                    defaultValue={values.name}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.name}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("sharedType")}</FormLabel>
                                                <CustomSelect options={typeOptions} isClearable name="type" defaultValue={values.type} onChange={(name, value) => { setFieldValue('period', 0); setFieldValue('start', 0); setFieldValue(name, value); }} />

                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("maintenanceStart")}</FormLabel>
                                                <CustomNumberField
                                                    type="number"
                                                    name="start"
                                                    xdatatype={values.type ? store.getState().maintenance_types.maintenance_types[values.type].dataType : null}
                                                    value={values.start}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.start}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("maintenancePeriod")}</FormLabel>
                                                <CustomNumberField
                                                    type="number"
                                                    name="period"
                                                    xdatatype={values.type ? store.getState().maintenance_types.maintenance_types[values.type].dataType : null}
                                                    value={values.period}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.period}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <footer className="footer">
                                        <div className="container d-flex">
                                            <div className="flex-grow-1 d-flex">
                                                <Button variant="secondary" className="align-self-stretch" onClick={() => this.openAttributesPage()}>
                                                    <i className="mdi mdi-playlist-play"></i> {t('sharedAttributes')}
                                                </Button>
                                            </div>
                                            <Button variant="secondary" onClick={() => this.cancel()}>
                                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                                            </Button>
                                            <Button type="submit" variant="primary" disabled={!this.state.changed && (!dirty || isSubmitting || !isValid)}>
                                                <i className="mdi mdi-content-save"></i> {t('sharedSave')}
                                            </Button>
                                        </div>
                                    </footer>
                                </React.Fragment>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </React.Fragment>
    }
}