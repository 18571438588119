import React from 'react';
import { store } from '../../store';
import { t } from '../../utils/Translator';
import CustomSelect from '../Controls/CustomSelect';
import { Scrollbars } from 'react-custom-scrollbars';
import { FormGroup, FormLabel, Button } from 'react-bootstrap';
import CustomCheckbox from '../Controls/CustomCheckBox';
import Datetime from 'react-datetime'
import { App } from '../../App';
import { ReportsService } from '../../services';
import Events from '../../utils/Events';

export default class ReportsConfig extends React.Component {
    constructor(props) {
        super(props);

        const allReports = store.getState().report_types.report_types;
        const reportOptions = [];
        Object.values(allReports).map(rt => {
            reportOptions.push({ value: rt.key, label: t(rt.name) });
        })

        const allDevices = store.getState().devices.devices;
        const deviceOptions = [];
        Object.values(allDevices).map(d => {
            deviceOptions.push({ value: d.id, label: d.name });
        })
        deviceOptions.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase()? 1: -1)


        const allGroups = store.getState().groups.groups;
        const groupOptions = [];
        Object.values(allGroups).filter(o => o.id > 0).map(d => {
            groupOptions.push({ value: d.id, label: d.name });
        })

        groupOptions.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase()? 1: -1)

        const attributeOptions = [];
        Object.values(store.getState().position_attributes.position_attributes).map(o => {
            if (o.valueType === 'number') {
                attributeOptions.push({ label: t(o.name), value: o.key })
            }
        })
        attributeOptions.push({ label: t('positionAltitude'), value: 'altitude' });
        attributeOptions.push({ label: t('positionAccuracy'), value: 'accuracy' });
        attributeOptions.push({ label: t('positionSpeed'), value: 'speed' });
        attributeOptions.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);

        const periodOptions = [
            { label: t('reportCustom'), value: 'custom' },
            { label: t('reportToday'), value: 'today' },
            { label: t('reportYesterday'), value: 'yesterday' },
            { label: t('reportThisWeek'), value: 'thisWeek' },
            { label: t('reportPreviousWeek'), value: 'previousWeek' },
            { label: t('reportThisMonth'), value: 'thisMonth' },
            { label: t('reportPreviousMonth'), value: 'previousMonth' },
        ];

        const eventTypeOptions = []
        const allNotificationTypes = store.getState().notification_types.notification_types;
        Object.values(allNotificationTypes).map(d => {
            eventTypeOptions.push({ value: d.type, label: t('event' + d.type.ucFirst()) });
        })

        eventTypeOptions.sort((a, b) => a.label > b.label ? 1 : -1);
        eventTypeOptions.unshift({ label: t('eventAll'), value: 'eventAll' });

        this.state = {
            reportOptions: reportOptions,
            allReports: allReports,
            currentType: null,
            deviceOptions: deviceOptions,
            allDevices: allDevices,
            selectedDevices: '',
            groupOptions: groupOptions,
            allGroups: allGroups,
            selectedGroups: '',
            showMarkers: false,
            periodOptions: periodOptions,
            selectedPeriod: '',
            eventTypeOptions: eventTypeOptions,
            selectedEventTypes: '',
            attributeOptions: attributeOptions,
            selectedChartType: null,
            period: {
                from: new Date(new Date().getTime() - 30 * 60 * 1000),
                to: new Date()
            },

        }

        this.renderConfigOptions = this.renderConfigOptions.bind(this);
        this.changeType = this.changeType.bind(this);
        this.changeSelectedDevices = this.changeSelectedDevices.bind(this);
        this.changeSelectedGroups = this.changeSelectedGroups.bind(this);
        this.toggleMarkers = this.toggleMarkers.bind(this);
        this.changeSelectedPeriod = this.changeSelectedPeriod.bind(this);
        this.changePeriod = this.changePeriod.bind(this);
        this.showReport = this.showReport.bind(this);
        this.changeSelectedEvents = this.changeSelectedEvents.bind(this);
        this.changeSelectedChartType = this.changeSelectedChartType.bind(this);
    }

    changeSelectedDevices(name, value) {
        this.setState({
            selectedDevices: value
        })
    }

    changeSelectedGroups(name, value) {
        this.setState({
            selectedGroups: value
        })
    }

    changeSelectedEvents(name, value) {
        if (value.includes('eventAll')) {
            value = 'eventAll';
        }
        this.setState({
            selectedEventTypes: value
        })
    }

    toggleMarkers() {
        this.setState({
            showMarkers: !this.state.showMarkers
        })
    }

    changeSelectedPeriod(name, value) {
        const custom = value === 'custom';
        let day, first, from, to;
        if (!custom) {
            from = new Date();
            to = new Date();
            switch (value) {
                case 'today':
                    to.setDate(to.getDate() + 1);
                    break;
                case 'yesterday':
                    from.setDate(to.getDate() - 1);
                    break;
                case 'thisWeek':
                    day = from.getDay();
                    first = from.getDate() - day + (day === 0 ? -6 : 1);
                    from.setDate(first);
                    to.setDate(first + 7);
                    break;
                case 'previousWeek':
                    day = from.getDay();
                    first = from.getDate() - day + (day === 0 ? -6 : 1);
                    from.setDate(first - 7);
                    to.setDate(first);
                    break;
                case 'thisMonth':
                    from.setDate(1);
                    to.setDate(1);
                    to.setMonth(from.getMonth() + 1);
                    break;
                case 'previousMonth':
                    from.setDate(1);
                    from.setMonth(from.getMonth() - 1);
                    to.setDate(1);
                    break;
                default:
                    break;
            }
            from.setHours(0, 0, 0, 0);
            to.setHours(0, 0, 0, 0);
            this.setState({
                period: {
                    from: from,
                    to: to,
                }
            })
        }
        this.setState({
            selectedPeriod: value,
        })
    }

    changePeriod(type, value) {
        this.setState({
            period: {
                ...this.state.period,
                [type]: value
            }
        })
    }

    clearReport() {

    }

    changeSelectedChartType(type, value) {
        this.setState({
            selectedChartType: value,
        })
    }

    async showReport() {
        const filter = {
            from: this.state.period.from.toISOString(),
            to: this.state.period.to.toISOString(),
            deviceId: this.state.selectedDevices.split(','),
            groupId: this.state.selectedGroups.split(','),
        };
        if (this.state.currentType.key === 'events') {
            filter.type = this.state.selectedEventTypes.split(',');
        }
        document.body.classList.add('show-report');
        Events.emit('reports/CLEAR')
        Events.emit('reports/SHOW', { reportType: this.state.currentType });
        Events.emit('CLOSE_SIDEBAR');
        App.App.showLoading();

        const type = this.state.currentType.key != 'chart' ? this.state.currentType.key : 'route';
        try {
            const data = await ReportsService.getReport(type, filter, {
                showMarkers: this.state.showMarkers,
                chartType: this.state.selectedChartType,
            });
            Events.emit('reports/DATA', data)
        } catch (ex) {
            App.App.showError(ex);
        } finally {
            App.App.hideLoading();
        }
    }

    async exportReport(email) {
        const filter = {
            from: this.state.period.from.toISOString(),
            to: this.state.period.to.toISOString(),
            deviceId: this.state.selectedDevices.split(','),
            groupId: this.state.selectedGroups.split(','),
            mail: email
        };

        const type = this.state.currentType.key != 'chart' ? this.state.currentType.key : 'route';
        try {
            const data = await ReportsService.reportExel(type, filter, email);
        } catch (ex) {
            App.App.showError(ex);
        }
    }


    renderConfigOptions() {
        const { deviceOptions, groupOptions, selectedDevices, selectedGroups, showMarkers, periodOptions, selectedPeriod, period, eventTypeOptions, selectedEventTypes, selectedChartType, attributeOptions } = this.state;
        return (
            <React.Fragment>

                <React.Fragment>
                    <FormGroup className="m-3">
                        <FormLabel>{t('deviceTitle')}</FormLabel>
                        <CustomSelect className="custom-select-margin-50" isMulti options={deviceOptions} defaultValue={selectedEventTypes} onChange={this.changeSelectedDevices} />
                    </FormGroup>

                    <FormGroup className="m-3">
                        <FormLabel>{t('settingsGroups')}</FormLabel>
                        <CustomSelect className="custom-select-margin-50" isMulti options={groupOptions} defaultValue={selectedGroups} onChange={this.changeSelectedGroups} />
                    </FormGroup>

                    {this.state.currentType && this.state.currentType.key === 'events' ?
                        <FormGroup className="m-3">
                            <FormLabel>{t('reportEvents')}</FormLabel>
                            <CustomSelect className="custom-select-margin-50" isClearable={false} isMulti options={eventTypeOptions} defaultValue={selectedEventTypes} onChange={this.changeSelectedEvents} />
                        </FormGroup>
                        : null}

                    {this.state.currentType && this.state.currentType.key === 'chart' ?
                        <FormGroup className="m-3">
                            <FormLabel>{t('reportChartType')}</FormLabel>
                            <CustomSelect className="custom-select-margin-50" options={attributeOptions} defaultValue={selectedChartType} onChange={this.changeSelectedChartType} />
                        </FormGroup>
                        : null}

                    <FormGroup className="m-3">
                        <CustomCheckbox defaultChecked={showMarkers} onChange={this.toggleMarkers} label={t('reportShowMarkers')} />
                    </FormGroup>

                    <FormGroup className="m-3">
                        <FormLabel>{t('reportPeriod')}</FormLabel>
                        <CustomSelect className="custom-select-margin-50" isClearable={false} options={periodOptions} defaultValue={selectedPeriod} onChange={this.changeSelectedPeriod} />
                    </FormGroup>
                    {selectedPeriod === 'custom' ? <React.Fragment>
                        <div className="period">
                            <FormGroup className="m-3">
                                <FormLabel>{t("reportFrom")}</FormLabel>
                                <Datetime timeFormat={"HH:mm"} dateFormat="YYYY-MM-DD"
                                    defaultValue={period.from}
                                    onChange={(e) => this.changePeriod('from', e)}> </Datetime>
                            </FormGroup>
                            <FormGroup className="m-3">
                                <FormLabel>{t("reportTo")}</FormLabel>
                                <Datetime timeFormat={"HH:mm"} dateFormat="YYYY-MM-DD"
                                    defaultValue={period.to}
                                    onChange={(e) => this.changePeriod('to', e)}> </Datetime>
                            </FormGroup>
                        </div>
                    </React.Fragment> : null}
                    <div className="m-3">
                        <Button className="btn-block" size="sm" disabled={!selectedPeriod || !this.state.currentType || (!selectedDevices && !selectedGroups) || (this.state.currentType.key === 'chart' && !this.state.selectedChartType)} onClick={this.showReport} variant="primary">{t('reportShow')}</Button>

                        {this.state.currentType.key !== 'chart' ? <Button className="btn-block" size="sm" disabled={!selectedPeriod || !this.state.currentType || (!selectedDevices && !selectedGroups)} onClick={() => this.exportReport(false)} variant="secondary">{t('reportExport')}</Button> : null}
                        {this.state.currentType.key !== 'chart' ? <Button className="btn-block" size="sm" disabled={!selectedPeriod || !this.state.currentType || (!selectedDevices && !selectedGroups)} onClick={() => this.exportReport(true)} variant="secondary">{t('reportEmail')}</Button> : null}
                        {this.state.showingReport ? <Button className="btn-block" size="sm" onClick={this.clearReport} variant="secondary">{t('reportClear')}</Button> : null}
                    </div>
                </React.Fragment>
            </React.Fragment>
        )
    }

    changeType(name, value) {
        this.setState({
            currentType: this.state.allReports[value],
        })
    }

    render() {
        const { currentType, reportOptions } = this.state;
        return (<React.Fragment>
            <div className="filter border-bottom">
                <FormGroup className="m-3">
                    <FormLabel>{t('sharedType')}</FormLabel>
                    <CustomSelect className="custom-select-margin-50" options={reportOptions} defaultValue={currentType ? currentType.key : null} onChange={this.changeType} />
                </FormGroup>
            </div>
            <div className="content reports-config">
                <Scrollbars>
                    {currentType ? this.renderConfigOptions() : null}
                </Scrollbars>
            </div>
        </React.Fragment>)
    }
}