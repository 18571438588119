import { User } from '../models';
import ApiStoreService from './ApiStoreService';
import users from '../store/users';

export class UsersService extends ApiStoreService {
    constructor() {
        super();
        this.baseUrl = 'users';
        this.storeName = users;
    }

    transformSingleResponse(data) {
        return new User().deserialize(data);
    }
}
export default new UsersService();