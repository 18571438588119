import React from 'react';
import AttributesPage from '../Attributes';
import { Button, Form, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { t } from '../../../utils/Translator';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Device } from '../../../models';
import { deepClone, isEqual } from '../../../utils/functions';
import device_images from '../../../config/device_images';
import CustomSelect from '../../Controls/CustomSelect';
import CustomCheckbox from '../../Controls/CustomCheckBox';
import { store } from '../../../store';
import PermissionsToolbar from '../Permissions/toolbar';
import Collapse from '../../Collapse';
import CustomNumberField from '../../Controls/CustomNumberField';
import { DevicesService } from '../../../services';
import { AppService } from '../../../services';

export default class DeviceForm extends React.Component {
    constructor(props) {
        super(props);

        const categoryOptions = [];
        device_images.ids.map(v => {
            categoryOptions.push({ value: v, label: t('category' + v.ucFirst()) });
        })

        categoryOptions.sort((a, b) => a.label > b.label ? 1 : -1);

        const groupOptions = [];
        Object.values(store.getState().groups.groups).map(v => {
            if (v.id > 0) {
                groupOptions.push({ value: v.id, label: v.name });
            }

        })



        groupOptions.sort((a, b) => a.label > b.label ? 1 : -1);


        this.state = {
            attributesPage: false,
            item: new Device().deserialize(deepClone(props.item)),
            mode: props.mode,
            sending: false,
            changed: false,
            categoryOptions: categoryOptions,
            groupOptions: groupOptions,
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.openAttributesPage = this.openAttributesPage.bind(this);
        this.onAttributesCancel = this.onAttributesCancel.bind(this);
        this.onAttributesClosed = this.onAttributesClosed.bind(this);
    }


    async save(values) {
        const item = this.state.item.deserialize(values);
        if(this.props.onSave){
            this.props.onSave(item);
        }else{
            try{
                if(this.state.item.id>0){
                   await DevicesService.update(this.state.item);
                }else{
                    await DevicesService.save(this.state.item);
                }
            }catch(ex){
                AppService.showError(ex);
            }
           
        }
        
    }

    cancel() {
        this.props.onHide();
    }

    openAttributesPage() {
        this.setState({ attributesPage: true })
    }

    async saveAccumulators(values) {
        try {
            await DevicesService.saveAcumulators({
                deviceId: this.state.item.id,
                totalDistance: values.totalDistance,
                hours: values.hours
            });
        } catch (ex) {
            AppService.showError(ex);
        }
    }

    onAttributesCancel() {
        const clone = new Device().deserialize(deepClone(this.props.item));
        const item = this.state.item;
        item.attributes = clone.attributes;
        this.setState({
            ...this.state,
            item,
            attributesPage: false
        })
    }

    onAttributesClosed() {
        this.setState({
            ...this.state,
            attributesPage: false,
            changed: !isEqual(this.state.item.attributes, this.props.item.attributes)
        })
    }


    componentWillReceiveProps(newProps, newState) {
        this.setState({ item: new Device().deserialize(deepClone(newProps.item)) })
    }



    render() {
        const { sending, item, categoryOptions, groupOptions } = this.state;

        const position = store.getState().positions.positions[item.id];

        const schema = yup.object({
            name: yup.string().required(),
            uniqueId: yup.string().required(),
            category: yup.string().required(),
        });

        return <React.Fragment>
            {this.state.attributesPage ?
                <AttributesPage item={item} type="devicesAttributes" onHide={() => this.onAttributesClosed()} onSave={() => this.onAttributesClosed()} onCancel={() => this.onAttributesCancel()} />
                : null}

            <div className="container-wrapper">
                <Formik
                    validationSchema={schema}
                    onSubmit={this.save}
                    disabled={sending}
                    initialValues={{
                        name: item.name,
                        uniqueId: item.uniqueId,
                        category: item.category,
                        groupId: item.groupId,
                        phone: item.phone,
                        model: item.model,
                        contact: item.contact,
                        disabled: item.disabled,
                        totalDistance: position ? position.getAttribute('totalDistance') : 0.00,
                        hours: position ? position.getAttribute('hours') : 0,
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        handleChange,
                        dirty,
                        isSubmitting,
                        setFieldValue,
                        errors,
                        isValid }) => {
                        return (

                            <Form onSubmit={handleSubmit} noValidate className="d-flex flex-column h-100 flex-grow-1 form form-sm ">
                                <React.Fragment>
                                    <div className="flex-grow-1 pt-3 overflow">
                                        <div className="container ">
                                            <h3 className="internal-title"><i className="mdi mdi-car"></i> {this.state.item.id ? t('sharedEdit') : t('sharedAdd')} {t('sharedDevice')}</h3>
                                            {this.state.item.id ? <PermissionsToolbar item={this.state.item} /> : null}

                                            <FormGroup>
                                                <FormLabel>{t("sharedName")}</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="name"
                                                    defaultValue={values.name}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.name}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("deviceIdentifier")}</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="uniqueId"
                                                    defaultValue={values.uniqueId}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.uniqueId}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("deviceCategory")}</FormLabel>
                                                <CustomSelect options={categoryOptions} name="category" isClearable defaultValue={values.category} onChange={setFieldValue} />
                                                {/*<div className="category-options">
                                                    {categoryOptions.map(o => {
                                                        return (
                                                            <div className={"category-option " + (o.value === values.category ? 'selected' : '')} key={o.value}>
                                                                <img width="80px" src={DeviceImages.formatSrc(DeviceImages.getImageSvg(constants.device.colors.online, null, false, 0, o.value))} />
                                                            </div>
                                                        )
                                                    })}
                                                </div>*/}
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("groupParent")}</FormLabel>
                                                <CustomSelect options={groupOptions} name="groupId" isClearable defaultValue={values.groupId} onChange={setFieldValue} />
                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel>{t("sharedPhone")}</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="phone"
                                                    defaultValue={values.phone}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.phone}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel>{t("deviceModel")}</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="model"
                                                    defaultValue={values.model}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.model}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <FormLabel>{t("deviceContact")}</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="contact"
                                                    defaultValue={values.contact}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.contact}
                                                />
                                            </FormGroup>

                                            <FormGroup className="mb-3">
                                                <CustomCheckbox name="disabled" defaultChecked={values.disabled} value={true} onChange={handleChange} label={t('sharedDisabled')} />
                                            </FormGroup>

                                            {position ? <div className="mt-3">
                                                <Collapse title={<h5 className="m-0">{t('sharedDeviceAccumulators')}</h5>}>
                                                    <div>
                                                        <FormGroup>
                                                            <FormLabel>{t("deviceTotalDistance")}</FormLabel>
                                                            <CustomNumberField xdatatype='distance' name="totalDistance" value={values.totalDistance} onChange={handleChange} />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <FormLabel>{t("positionHours")}</FormLabel>
                                                            <CustomNumberField xdatatype='hours' name="hours" value={values.hours} onChange={handleChange} />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Button type="button" variant="primary" onClick={() => this.saveAccumulators(values)}>
                                                                <i className="mdi mdi-content-save"></i> {t('sharedSave')}
                                                            </Button>
                                                        </FormGroup>
                                                    </div>
                                                </Collapse>
                                            </div> : null}
                                        </div>
                                    </div>
                                    <footer className="footer">
                                        <div className="container d-flex">
                                            <div className="flex-grow-1 d-flex">
                                                <Button variant="secondary" className="align-self-stretch" onClick={() => this.openAttributesPage()}>
                                                    <i className="mdi mdi-playlist-play"></i> {t('sharedAttributes')}
                                                </Button>
                                            </div>
                                            <Button variant="secondary" onClick={() => this.cancel()}>
                                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                                            </Button>
                                            <Button type="submit" variant="primary" disabled={!this.state.changed && (!dirty || isSubmitting || !isValid)}>
                                                <i className="mdi mdi-content-save"></i> {t('sharedSave')}
                                            </Button>
                                        </div>
                                    </footer>
                                </React.Fragment>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </React.Fragment>
    }
}